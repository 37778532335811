import { defineStore } from 'pinia'
import { Album, AlbumPhoto, EventPhoto } from '../api.ts'
import { useScopedEventStore } from './EventStore.ts'
import { generateClient } from 'aws-amplify/api'
import {
    albumPhotosByAlbumId,
    albumsByEventId,
    getAlbum,
} from '../graphql/queries.ts'
import { createAlbum, createAlbumPhoto } from '../graphql/mutations.ts'

interface eventScopedAlbumState {
    selectedPhotosIds: string[]
    albums: Album[]
}

interface albumScopedAlbumState {
    album: Album | undefined
    albumPhotos: AlbumPhoto[]
    loading: boolean
}

const client = generateClient({
    authMode: 'userPool',
})

export function useEventScopedAlbumStore(eventId: string) {
    const store = defineStore(`feature/albums::${eventId}`, {
        state: (): eventScopedAlbumState => ({
            selectedPhotosIds: [],
            albums: [],
        }),
        getters: {
            selectedPhotosCount: (state: eventScopedAlbumState) =>
                state.selectedPhotosIds.length,
            isPhotoSelected:
                (state: eventScopedAlbumState) => (photo: EventPhoto) => {
                    return state.selectedPhotosIds.includes(photo.id)
                },
            selectedPhotos: (state: eventScopedAlbumState) => {
                const eventStore = useScopedEventStore(eventId)
                return eventStore.eventPhotos.filter((photo) =>
                    state.selectedPhotosIds.includes(photo.id)
                )
            },
        },
        actions: {
            async fetchAlbums() {
                if (this.albums.length > 0) {
                    return this.albums
                }

                const response = await client.graphql({
                    query: albumsByEventId,
                    variables: {
                        eventId: eventId,
                        limit: 100,
                    },
                })

                this.albums = response.data.albumsByEventId.items

                return this.albums
            },
            async createAlbum(name: string) {
                const response = await client.graphql({
                    query: createAlbum,
                    variables: {
                        input: {
                            name: name,
                            eventId: eventId,
                        },
                    },
                })

                this.albums.push(response.data.createAlbum as Album)

                console.log(response)

                return response.data.createAlbum
            },
            togglePhotoSelected(photo: EventPhoto) {
                if (this.selectedPhotosIds.includes(photo.id)) {
                    this.selectedPhotosIds = this.selectedPhotosIds.filter(
                        (p) => p !== photo.id
                    )
                } else {
                    this.selectedPhotosIds.push(photo.id)
                }
            },
            selectAllPhotos() {
                const eventStore = useScopedEventStore(eventId)
                this.selectedPhotosIds = eventStore.eventPhotos.map(
                    (photo) => photo.id
                )
            },
            clearSelectedPhotos() {
                this.selectedPhotosIds = []
            },
        },
    })

    return store()
}

export function useAlbumScopedAlbumStore(eventId: string, albumId: string) {
    const store = defineStore(`feature/albums::${eventId}::${albumId}`, {
        state: (): albumScopedAlbumState => ({
            album: undefined,
            albumPhotos: [],
            loading: false,
        }),
        getters: {},
        actions: {
            async fetchAlbum() {
                if (this.album) {
                    return this.album
                }

                this.loading = true

                const response = await client.graphql({
                    query: getAlbum,
                    variables: {
                        id: albumId,
                    },
                })

                if (!response.data.getAlbum) {
                    throw new Error('Album not found')
                }

                this.album = response.data.getAlbum as Album

                this.loading = false

                return this.album
            },
            async fetchAlbumPhotos() {
                if (this.albumPhotos.length > 0) {
                    return this.albumPhotos
                }

                this.loading = true

                const response = await client.graphql({
                    query: albumPhotosByAlbumId,
                    variables: {
                        albumId: albumId,
                    },
                })

                this.albumPhotos = response.data.albumPhotosByAlbumId.items

                this.loading = false

                return this.albumPhotos
            },
            async addPhotosToAlbum(photoIds: string[]) {
                if (!photoIds.length) {
                    throw new Error('No photos to add')
                }

                this.loading = true

                for (const photoId of photoIds) {
                    if (!photoId) {
                        continue
                    }

                    await client.graphql({
                        query: createAlbumPhoto,
                        variables: {
                            input: {
                                albumId: albumId,
                                eventPhotoAlbumPhotosId: photoId,
                            },
                        },
                    })
                }

                this.loading = false
            },
        },
    })

    return store()
}
