<template>
    <div class="fixed h-dvh w-screen top-0 left-0 z-30"></div>
    <video
        ref="video"
        class="fixed h-dvh w-screen top-0 left-0 z-10"
        :class="{ hidden: !deviceId, classList: true }"
        autoplay
        muted
        playsinline
        @click="handleVideoClick"
    />
    <canvas ref="canvas" style="display: none" />
    <div class="hidden">
        <audio
            ref="audio"
            volume="0.5"
            src="https://www.soundjay.com/mechanical/camera-shutter-click-08.mp3"
        ></audio>
    </div>
    <div ref="shutter" class="shutter z-20"></div>
</template>

<style>
.w-full {
    width: 100%;
}
.h-auto {
    height: auto;
}
.hidden {
    display: none;
}
.shutter {
    opacity: 0;
    transition: all 30ms ease-in;
    position: fixed;
    height: 0%;
    width: 0%;
    pointer-events: none;

    background-color: black;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.shutter.on {
    opacity: 1; /* Shutter Transparency */
    height: 100%;
    width: 100%;
}
video::-webkit-media-controls-start-playback-button {
    display: none !important;
}
</style>

<script lang="ts">
import deviceorientation from 'deviceorientation-js'

const frontCameras = ['front', 'user', 'facetime']

export default {
    name: 'Webcam',
    props: {
        // try to use these device instead of first one, if the camera label has any keyword from this list
        preferCamerasWithLabel: {
            type: Array,
            default: ['back', 'front'],
        },
        // class list of video element
        classList: {
            type: String,
            default: 'w-full h-auto',
        },
        // constraints that will be passed to getUserMedia, you can specify preferred resolution, facing direction etc.
        constraints: {
            type: Object,
            default: {
                video: { width: { ideal: 2560 }, height: { ideal: 1440 } },
                facingMode: 'user',
            },
        },
        // if device has gyroscope and the device is rotated (for example in landscape mode), this will try to rotate the image
        tryToRotateImage: {
            type: Boolean,
            default: true,
        },
        // output image
        imageType: {
            type: String,
            default: 'image/jpeg',
        },
        // if should automatically start and select the best device depending to preferCamerasWithLabel and constraints, or selects first device
        autoStart: {
            type: Boolean,
            default: true,
        },
    },
    data(): {
        deviceId: string | null
        cameras: MediaDeviceInfo[]
        innited: boolean
    } {
        return {
            deviceId: null,
            cameras: [],
            innited: false,
        }
    },
    emits: ['photoTaken'],
    async mounted() {
        this.setupMedia()
        deviceorientation.init()
    },
    beforeUnmount() {
        this.stop()
    },
    methods: {
        handleVideoClick(event: Event) {
            event.preventDefault()
        },
        async loadCameras() {
            await navigator.mediaDevices
                .enumerateDevices()
                .then((deviceInfos) => {
                    for (let i = 0; i !== deviceInfos.length; ++i) {
                        let deviceInfo = deviceInfos[i]
                        if (
                            deviceInfo.deviceId !== '' &&
                            deviceInfo.kind === 'videoinput' &&
                            this.cameras.find(
                                (el) => el.deviceId === deviceInfo.deviceId
                            ) === undefined
                        ) {
                            this.cameras.push(deviceInfo)
                        }
                    }
                })
                .then(() => {
                    if (!this.innited) {
                        if (this.deviceId === null && this.autoStart) {
                            this.start()
                        }
                        this.innited = true
                    }
                })
                .catch((error) =>
                    console.error('Error getting video devices', error)
                )
        },
        async changeCamera(deviceId: string) {
            if (this.deviceId !== deviceId) {
                this.deviceId = deviceId
                return // will be recalled due to watcher
            }

            this.stop()

            if (deviceId) {
                await this.loadCamera(deviceId)
            }
        },
        async loadCamera(deviceId: string) {
            const camera = this.cameras.find((el) => el.deviceId === deviceId)

            const isFrontCamera = frontCameras.some((label) =>
                camera?.label.toLowerCase().includes(label)
            )

            await navigator.mediaDevices
                .getUserMedia(this.buildConstraints(deviceId))
                .then((stream) => {
                    const video = this.$refs.video as HTMLVideoElement

                    video.srcObject = stream
                    video.play()

                    if (isFrontCamera) {
                        video.style.transform = 'scaleX(-1)'
                    } else {
                        video.style.transform = 'scaleX(1)'
                    }

                    video.controls = false
                })
                .catch((err) => console.error(err))
        },
        testMediaAccess() {
            navigator.mediaDevices
                .getUserMedia(this.buildConstraints())
                .then((stream) => {
                    let tracks = stream.getTracks()
                    tracks.forEach((track) => {
                        track.stop()
                    })
                    this.loadCameras()
                })
                .catch((err) => console.log(err))
        },
        setupMedia() {
            this.testMediaAccess()
        },

        clear(video: HTMLVideoElement) {
            if (!video.srcObject) {
                return
            }
        },
        stop() {
            const video = this.$refs.video as HTMLVideoElement

            if (video.srcObject) {
                this.clear(video)
            }
        },
        async start() {
            if (this.deviceId) {
                await this.loadCamera(this.deviceId)
            } else {
                if (this.cameras.length > 1) {
                    for (const label of this.preferCamerasWithLabel) {
                        const camera = this.cameras.find(
                            (el) =>
                                el.label
                                    .toLowerCase()
                                    .indexOf(label as string) !== -1
                        )
                        if (camera) {
                            this.deviceId = camera.deviceId
                            break
                        }
                    }
                }

                if (this.deviceId) {
                    await this.loadCamera(this.deviceId)
                }
            }
        },

        buildConstraints(deviceId?: string): MediaStreamConstraints {
            const constraints: MediaStreamConstraints = {
                video: true,
                audio: false,
            }
            const c = { ...constraints, ...this.constraints }
            if (deviceId) {
                if (typeof c.video !== 'object' || c.video === null) {
                    c.video = {}
                }
                c.video.deviceId = { exact: deviceId }
            }
            return c
        },

        drawRotated(
            source: HTMLVideoElement,
            canvas: HTMLCanvasElement,
            context: CanvasRenderingContext2D,
            degrees: number
        ): void {
            const width = canvas.width
            const height = canvas.height

            if (degrees === 90 || degrees === 270) {
                canvas.width = height
                canvas.height = width
            }

            if (degrees === 90) {
                context.translate(height, 0)
            } else if (degrees === 180) {
                context.translate(width, height)
            } else if (degrees === 270) {
                context.translate(0, width)
            } else {
                context.translate(0, 0)
            }

            context.rotate((degrees * Math.PI) / 180)

            context.drawImage(
                source,
                -source.width / 2,
                -source.width / 2,
                width,
                height
            )
        },

        async takePhoto() {
            if (!this.$refs.video || !this.$refs.canvas) {
                return
            }

            let video = this.$refs.video as HTMLVideoElement
            let canvas = this.$refs.canvas as HTMLCanvasElement

            canvas.height = video.videoHeight
            canvas.width = video.videoWidth

            let ctx = canvas.getContext('2d')

            if (!ctx) {
                return
            }

            this.drawRotated(
                video,
                canvas,
                ctx,
                this.tryToRotateImage
                    ? deviceorientation.getDeviceOrientation()
                    : 0
            )

            let image_data_url = canvas.toDataURL(this.imageType)

            canvas.toBlob(
                (blob) => {
                    if (this.$refs.audio) {
                        const audio = this.$refs.audio as HTMLAudioElement
                        audio.play()
                    }

                    if (this.$refs.shutter) {
                        const shutter = this.$refs.shutter as HTMLElement

                        shutter.classList.add('on')
                        setTimeout(
                            () => {
                                if (!this.$refs.shutter) {
                                    return
                                }

                                shutter.classList.remove('on')
                            },
                            30 * 2 + 45
                        )
                    }

                    this.$emit('photoTaken', { blob, image_data_url })
                },
                this.imageType,
                1
            )
        },
    },
    watch: {
        deviceId: function (deviceId) {
            this.changeCamera(deviceId)
        },
    },
}
</script>
