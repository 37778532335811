import { defineStore } from 'pinia'
import {
    IPhotoModel,
    Photo,
    CapturePhotoModel,
} from '../models/CapturePhotoModel.ts'
import axios from 'axios'
import Dexie, { Table } from 'dexie'
import { retry } from '../common/retry.ts'
import { fetchAuthSession } from 'aws-amplify/auth'

class SnapPixDatabase extends Dexie {
    // 'friends' is added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    photos!: Table<Photo>

    constructor() {
        super('snapPixDatabase')
        this.version(1).stores({
            photos: '++id, eventId, deviceId, base64, uploaded', // Primary key and indexed props
        })
    }
}

const db = new SnapPixDatabase()

interface State {
    photos: IPhotoModel[]
    photosLoading: boolean
}

export const usePhotoStore = defineStore('PhotoStore', {
    state: (): State => ({
        /** @type: EventModel[] */
        photos: [],
        /** @type: boolean */
        photosLoading: false,
    }),
    getters: {
        count: (state: State) => state.photos.length,
    },
    actions: {
        async createPhoto(
            eventId: string,
            deviceId: string,
            photo: {
                blob: Blob
                image_data_url: string
            }
        ) {
            const id = await db.photos.add({
                eventId,
                deviceId,
                base64: photo.image_data_url,
                uploaded: false,
            })

            console.log(`Photo added with id ${id}`)

            this.photos.splice(
                0,
                0,
                CapturePhotoModel.fromDTO({
                    id,
                    eventId,
                    deviceId,
                    base64: photo.image_data_url,
                    uploaded: false,
                })
            )

            await this.uploadPhoto(id, eventId, deviceId, photo)

            return id
        },
        async uploadPhoto(
            id: number,
            eventId: string,
            deviceId: string,
            photo: {
                blob: Blob
                image_data_url: string
            }
        ) {
            const fileName = `${deviceId}-${new Date().getTime()}.jpeg`
            let uploadSuccessful = false

            const sendApiRequest = async () => {
                try {
                    const accessToken = (
                        await fetchAuthSession()
                    ).tokens?.accessToken.toString()

                    console.log('accessToken', accessToken)

                    const restOperation = axios.put(
                        `CapturePhoto/${eventId}/${fileName}`,
                        new File([photo.blob], fileName),
                        {
                            baseURL: import.meta.env.VITE_API_ENDPOINT,
                            headers: {
                                'Content-Type': 'image/jpeg',
                                Authorization: `123`,
                            },
                        }
                    )

                    const { data } = await restOperation

                    console.log('POST call succeeded')
                    console.log(data)

                    uploadSuccessful = true
                } catch (e) {
                    console.log('POST call failed: ', e)
                    throw e
                }
            }

            await retry(sendApiRequest, [], 100, 3000)

            if (uploadSuccessful) {
                await db.photos.update(id, { uploaded: true })

                const photoIndex = this.photos.findIndex(
                    (photo) => photo.id === id
                )

                this.photos[photoIndex].markUploaded()
            }

            return
        },
        async fetchPhotos(eventId: string) {
            try {
                this.photosLoading = true

                const photos = await db.photos
                    .filter((photo) => photo.eventId === eventId)
                    .reverse()
                    .toArray()

                console.log(photos)

                this.photos = photos.map((photo) =>
                    CapturePhotoModel.fromDTO(photo)
                )

                const photosNeedUploading = this.photos.filter(
                    (photo) => !photo.uploaded
                )

                for (const photo of photosNeedUploading) {
                    const response = await fetch(photo.base64Image)
                    const blob = await response.blob()

                    await this.uploadPhoto(
                        photo.id,
                        photo.eventId,
                        photo.deviceId,
                        { blob: blob, image_data_url: photo.base64Image }
                    )
                }
            } catch (error) {
                console.error(error)
                return undefined
            } finally {
                this.photosLoading = false
            }
        },
    },
})
