<script setup lang="ts">
import { Component, Ref } from 'vue'

type view = {
    name: string
    icon: string
    component: Component
    children?: Ref<{ name: string }[]>
}

type AlbumNavigationProps = { views: view[] }

defineEmits<{
    (e: 'viewClicked', viewName: string, data?: { name: string }): void
}>()

const props = defineProps<AlbumNavigationProps>()
</script>

<template>
    <div
        id="album-sidebar"
        class="h-dvh hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 border-amber-500 select-none animation-duration-300 animation-ease-in-out bg-amber-300"
        style="width: 280px; backdrop-filter: blur(10px)"
    >
        <div class="flex flex-column h-full">
            <div class="flex align-items-center px-5 flex-shrink-0 mt-6">
                <span class="text-xl text-stone-800">ALBUMS</span>
            </div>
            <div class="overflow-y-auto mt-3">
                <ul class="list-none p-3 m-0">
                    <li>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li v-for="view in props.views">
                                <template
                                    v-if="
                                        view.children &&
                                        view.children.value.length > 0
                                    "
                                >
                                    <a
                                        v-ripple
                                        v-styleclass="{
                                            selector: '@next',
                                            enterFromClass: 'hidden',
                                            enterActiveClass: 'slidedown',
                                            leaveToClass: 'hidden',
                                            leaveActiveClass: 'slideup',
                                        }"
                                        class="flex align-items-center cursor-pointer p-3 text-stone-800 hover:bg-purple-50 transition-duration-150 transition-colors p-ripple"
                                    >
                                        <i
                                            class="pi mr-2"
                                            :class="view.icon"
                                        ></i>
                                        <span class="font-medium"
                                            >{{ view.name }}
                                        </span>
                                        <i
                                            class="pi pi-chevron-down ml-auto"
                                        ></i>
                                    </a>
                                    <ul>
                                        <li
                                            v-for="child in view.children.value"
                                        >
                                            <a
                                                @click="
                                                    $emit(
                                                        'viewClicked',
                                                        view.name,
                                                        child
                                                    )
                                                "
                                                v-ripple
                                                class="flex align-items-center cursor-pointer p-3 text-stone-800 hover:bg-purple-50 transition-duration-150 transition-colors p-ripple"
                                            >
                                                <span>{{ child.name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </template>
                                <template v-else>
                                    <a
                                        v-ripple
                                        @click="$emit('viewClicked', view.name)"
                                        class="flex align-items-center cursor-pointer p-3 text-stone-800 hover:bg-purple-50 transition-duration-150 transition-colors p-ripple"
                                    >
                                        <i
                                            class="pi mr-2"
                                            :class="view.icon"
                                        ></i>
                                        <span class="font-medium"
                                            >{{ view.name }}
                                        </span>
                                    </a>
                                </template>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
