<script setup lang="ts">
import { toRefs, watch } from 'vue'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue'
import { useRouter } from 'vue-router'
import 'aws-amplify/auth/enable-oauth-listener'

const { route, toForgotPassword, toSignUp, toSignIn } =
    toRefs(useAuthenticator())

const router = useRouter()

watch(route, (to) => {
    if (to === 'authenticated') {
        if (router.currentRoute.value.query.redirect) {
            router.push(router.currentRoute.value.query.redirect.valueOf())
            return
        }

        router.push({
            name: 'EventList',
        })
    }
})
</script>

<template>
    <div
        style="
            background: url('/bokeh-background.jpg') no-repeat;
            background-size: cover;
            min-height: 100vh;
        "
        class="px-4 py-4 md:px-6 md:py-6 lg:px-8 lg:py-8 flex"
    >
        <div class="flex w-full flex-wrap">
            <div
                class="w-full lg:w-6 p-4 lg:p-7"
                style="background-color: rgba(255, 255, 255, 0.7)"
            >
                <img
                    src="/logo_small.png"
                    alt="Image"
                    height="100"
                    class="mb-3"
                />
                <div class="text-xl text-900 font-500 mb-3">
                    Welcome to Snap Pix
                </div>
                <p class="text-600 line-height-3 mt-0 lg:mb-6">
                    Login to create and manage your events.
                </p>
                <ul class="list-none p-0 m-0 hidden lg:block">
                    <li class="flex align-items-start mb-4">
                        <div>
                            <span
                                class="flex align-items-center justify-content-center bg-purple-400"
                                style="
                                    width: 38px;
                                    height: 38px;
                                    border-radius: 10px;
                                "
                            >
                                <i
                                    class="text-xl text-white pi pi-calendar"
                                ></i>
                            </span>
                        </div>
                        <div class="ml-3">
                            <span class="font-medium text-900"
                                >Create & Manage Events</span
                            >
                            <p class="mt-2 mb-0 text-600 line-height-3">
                                Create and manage your events with ease.
                            </p>
                        </div>
                    </li>
                    <li class="flex align-items-start mb-4">
                        <div>
                            <span
                                class="flex align-items-center justify-content-center bg-purple-400"
                                style="
                                    width: 38px;
                                    height: 38px;
                                    border-radius: 10px;
                                "
                            >
                                <i class="text-xl text-white pi pi-camera"></i>
                            </span>
                        </div>
                        <div class="ml-3">
                            <span class="font-medium text-900"
                                >View & Download Your Photos</span
                            >
                            <p class="mt-2 mb-0 text-600 line-height-3">
                                View and download all your guest photos for each
                                event.
                            </p>
                        </div>
                    </li>
                    <li class="flex align-items-start">
                        <div>
                            <span
                                class="flex align-items-center justify-content-center bg-purple-400"
                                style="
                                    width: 38px;
                                    height: 38px;
                                    border-radius: 10px;
                                "
                            >
                                <i class="text-xl text-white pi pi-qrcode"></i>
                            </span>
                        </div>
                        <div class="ml-3">
                            <span class="font-medium text-900"
                                >Download & Share QR Code</span
                            >
                            <p class="mt-2 mb-0 text-600 line-height-3">
                                Get your unique QR code to share with your
                                guest.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <authenticator
                :hide-sign-up="false"
                class="w-full lg:w-6 p-4 lg:p-7 surface-card"
            >
                <template v-slot:sign-in-header>
                    <div class="text-900 amplify-heading amplify-heading--3">
                        Login
                    </div>
                </template>

                <template v-slot:sign-up-header>
                    <div class="text-900 amplify-heading amplify-heading--3">
                        Register
                    </div>
                </template>

                <template v-slot:sign-in-footer>
                    <div class="flex align-items-center justify-end mb-6">
                        <Button
                            link
                            @click="toForgotPassword"
                            class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                            >Forgot password?</Button
                        >
                    </div>
                    <div
                        class="mt-6 text-center text-600 flex align-items-center"
                    >
                        Don't have an account?
                        <Button
                            link
                            @click="toSignUp"
                            class="font-medium text-blue-500"
                            >Sign up</Button
                        >
                    </div>
                </template>

                <template v-slot:sign-up-footer>
                    <div style="text-align: center">
                        <button
                            @click="toSignIn"
                            class="amplify-button amplify-field-group__control amplify-button--link amplify-button--small amplify-field-group__control amplify-authenticator__fontl"
                            data-fullwidth="false"
                            data-size="small"
                            data-variation="link"
                            type="button"
                            style="font-weight: normal"
                        >
                            Back to Sign In
                        </button>
                    </div>
                </template>
            </authenticator>
        </div>
    </div>
</template>

<style>
.amplify-label {
    font-weight: 600;
}

.amplify-input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #334155;
    background: #ffffff;
    padding: 0.5rem 0.75rem !important;
    border: 1px solid #cbd5e1 !important;
    transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s,
        outline-color 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 6px !important;
    outline-color: transparent !important;
}

.amplify-input[name='password'] {
    border-radius: 6px 0 0 6px !important;
}

.amplify-button[aria-label='Show password'] {
    border-radius: 0 6px 6px 0 !important;
    outline-color: transparent !important;
    border: 1px solid #cbd5e1 !important;
    border-left: none !important;
}
</style>
