<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import Stepper from 'primevue/stepper'
import StepPanel from 'primevue/steppanel'
import StepPanels from 'primevue/steppanels'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import ProgressSpinner from 'primevue/progressspinner'
import Galleria from 'primevue/galleria'
import {
    useAlbumScopedAlbumStore,
    useEventScopedAlbumStore,
} from '@stores/AlbumStore.ts'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { useField, useForm } from 'vee-validate'
import { Album, AlbumPhoto } from '@/api.ts'
interface AddToAlbumProps {
    eventId: string
    visible: boolean
}

type SelectedAlbum = {
    id: string | undefined
    name: string
    photos: AlbumPhoto[]
}

const props = defineProps<AddToAlbumProps>()
const emit = defineEmits(['update:visible'])

const albumStore = useEventScopedAlbumStore(props.eventId)

const visible = ref(props.visible)
const activeStep = ref(1)
const activeView = ref('default')

const selectedAlbum = ref<SelectedAlbum>()
const albums = ref<Album[]>()
const albumFilter = ref('')

const albumScopeStore = computed(() => {
    const album = selectedAlbum.value
    if (album && album.id) {
        return useAlbumScopedAlbumStore(props.eventId, album.id)
    }
    return undefined
})

const validationSchema = toTypedSchema(
    zod.object({
        name: zod.string().trim().min(3).max(50),
    })
)

const { handleSubmit, errors } = useForm({
    validationSchema,
})

const { value: name } = useField<string>('name')

const onSubmit = handleSubmit(async ({ name }) => {
    selectedAlbum.value = {
        id: undefined,
        name: name,
        photos: [],
    }

    await albumStore.createAlbum(name)

    activeStep.value = 2
})

const getCdnUrl = (path: string) => {
    return `${import.meta.env.VITE_CDN_URL}/${path}`
}

onMounted(async () => {
    albums.value = await albumStore.fetchAlbums()
})

watch(
    () => props.visible,
    (value) => {
        visible.value = value
        reset()
    }
)

watch(
    () => visible.value,
    (value) => {
        emit('update:visible', value)
    }
)

const filteredAlbums = computed(() => {
    return albums.value?.filter((album) =>
        album.name.toLowerCase().includes(albumFilter.value.toLowerCase())
    )
})

const photosToBeAddedToAlbum = computed(() => {
    const albumEventPhotoIds = selectedAlbum.value?.photos.map(
        (photo) => photo.eventPhotoAlbumPhotosId
    )
    return albumStore.selectedPhotos.filter(
        (photo) => !albumEventPhotoIds?.includes(photo.id)
    )
})

function reset() {
    activeStep.value = 1
    name.value = ''
    activeView.value = 'default'
    selectedAlbum.value = undefined
}

async function handleAlbumSelected(item: Album) {
    selectedAlbum.value = {
        id: item.id,
        name: item.name,
        photos: [],
    }

    if (!albumScopeStore.value) {
        console.error('No album scope store')
        return
    }

    await albumScopeStore.value.fetchAlbumPhotos()

    selectedAlbum.value.photos = albumScopeStore.value.albumPhotos

    console.log('handleAlbumSelected', selectedAlbum.value)

    activeView.value = 'default'
    activeStep.value = 2
}

async function confirmAlbum() {
    if (!selectedAlbum.value) {
        console.error('No album selected')
        return
    }

    const photosToBeAdded = photosToBeAddedToAlbum.value.map(
        (photo) => photo.id
    )

    console.log('photosToBeAdded', photosToBeAdded)

    await albumScopeStore.value?.addPhotosToAlbum(photosToBeAdded)

    console.log('Album Created')

    visible.value = false
}

const viewTitle = computed(() => {
    switch (activeStep.value) {
        case 1:
            switch (activeView.value) {
                case 'new-album':
                    return 'CREATE NEW ALBUM'
                case 'existing-album':
                    return 'SELECT AN ALBUM'
                default:
                    return 'CREATE OR SELECT AN ALBUM'
            }
        case 2:
            return 'CONFIRMATION'
        default:
            return 'CREATE OR SELECT AN ALBUM'
    }
})
</script>

<template>
    <div
        class="flex h-full surface-card p-4 flex-col text-gray-200"
        v-if="visible"
    >
        <Stepper
            v-model:value="activeStep"
            linear
            class="w-full h-full flex flex-column"
        >
            <div class="flex mt-3 mb-2 justify-between mx-2">
                <div>
                    <Button
                        label="Back"
                        icon="pi pi-arrow-left"
                        class="mr-3"
                        @click="
                            () => {
                                if (activeStep > 1) {
                                    activeStep = activeStep - 1
                                }

                                activeView = 'default'
                            }
                        "
                        v-if="activeView !== 'default' || activeStep !== 1"
                    />
                    <span class="text-gray-600 font-bold text-2xl">{{
                        viewTitle
                    }}</span>
                </div>
                <Button
                    icon="pi pi-times"
                    severity="danger"
                    rounded
                    outlined
                    aria-label="Cancel"
                    class="ml-auto"
                    @click="visible = false"
                />
            </div>
            <StepPanels class="flex-grow-1">
                <StepPanel :value="1" class="h-full justify-between flex-col">
                    <div
                        v-if="activeView === 'default'"
                        class="flex flex-row h-full w-full justify-evenly items-center"
                    >
                        <Button
                            rounded
                            raised
                            class="h-10 p-4"
                            @click="activeView = 'new-album'"
                            >Create new album</Button
                        >
                        <Divider layout="vertical" align="center" type="dotted">
                            <b class="text-2xl">OR</b>
                        </Divider>
                        <Button
                            raised
                            rounded
                            class="h-10 p-4"
                            @click="activeView = 'existing-album'"
                            >Add to existing album</Button
                        >
                    </div>
                    <div
                        v-if="activeView === 'new-album'"
                        class="flex flex-col flex-grow-1 h-full"
                    >
                        <div
                            class="border-2 border-surface-200 rounded bg-surface-50 flex-auto flex justify-center items-center font-medium"
                        >
                            <form id="newAlbumForm" @submit.prevent="onSubmit">
                                <div class="grid formgrid p-fluid flex">
                                    <div
                                        class="field mb-4 col-12 flex flex-col gap-2"
                                    >
                                        <label for="album-name"
                                            >Album Name</label
                                        >
                                        <InputText
                                            id="album-name"
                                            v-model="name"
                                            aria-describedby="album-name-help"
                                        />
                                        <small id="album-name-help"
                                            >Enter a name for your new
                                            album</small
                                        >
                                        <InlineMessage
                                            v-if="errors.name"
                                            class="mt-3"
                                            >{{ errors.name }}
                                        </InlineMessage>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="flex mt-3 items-center justify-content-end">
                            <Button
                                label="Continue"
                                icon="pi pi-arrow-right"
                                form="newAlbumForm"
                                type="submit"
                            />
                        </div>
                    </div>
                    <div
                        v-if="activeView === 'existing-album'"
                        class="flex flex-col flex-grow-1 h-full"
                    >
                        <template v-if="albumScopeStore?.loading">
                            <div class="flex items-center">
                                <ProgressSpinner />
                            </div>
                        </template>
                        <template v-else>
                            <div class="flex mb-3 items-center justify-between">
                                <div class="flex">
                                    <IconField class="mb-1">
                                        <InputIcon class="pi pi-search" />
                                        <InputText
                                            v-model="albumFilter"
                                            placeholder="Search"
                                        />
                                    </IconField>
                                </div>
                            </div>
                            <div
                                class="border-2 border-surface-200 rounded bg-surface-50 flex-auto flex justify-center items-center font-medium"
                            >
                                <DataView
                                    :value="filteredAlbums"
                                    data-key="id"
                                    paginator
                                    :rows="4"
                                    class="w-full h-full justify-between flex flex-col album-data-view"
                                >
                                    <template #list="slotProps">
                                        <div class="flex flex-col h-full">
                                            <a
                                                v-ripple
                                                class="h-1/4 p-2 hover:bg-amber-300 hover:text-gray-200 hover:cursor-pointer text-gray-200"
                                                v-for="(
                                                    item, index
                                                ) in slotProps.items"
                                                :key="index"
                                                @click="
                                                    handleAlbumSelected(item)
                                                "
                                            >
                                                <div
                                                    class="flex align-items-center h-full"
                                                >
                                                    <span
                                                        class="block mr-3 surface-200 border-radius: 10px justify-content-center align-items-center"
                                                        style="
                                                            width: 58px;
                                                            height: 58px;
                                                            border-radius: 10px;
                                                        "
                                                    >
                                                    </span>
                                                    <div>
                                                        <div
                                                            class="font-medium text-xl mb-2"
                                                        >
                                                            {{ item.name }}
                                                        </div>
                                                        <div
                                                            class="flex align-items-center"
                                                        >
                                                            <i
                                                                class="pi pi-calendar mr-2"
                                                            ></i>
                                                            <span>{{
                                                                new Date(
                                                                    item.createdAt
                                                                ).toLocaleDateString(
                                                                    undefined,
                                                                    {
                                                                        weekday:
                                                                            'long',
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    }
                                                                )
                                                            }}</span>
                                                            <i
                                                                class="pi pi-calendar text-600 mr-2 ml-3"
                                                            ></i>
                                                            <span
                                                                class="inline-flex font-medium border-round"
                                                                >100
                                                                Photos</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </template>
                                </DataView>
                            </div>
                        </template>
                    </div>
                </StepPanel>
                <StepPanel
                    v-slot="{ activateCallback }"
                    :value="2"
                    class="h-full justify-between flex-col"
                >
                    <div class="flex flex-col flex-grow-1 h-full">
                        <div
                            class="border-2 border-surface-200 rounded bg-surface-50 flex-auto flex justify-center items-center font-medium h-full"
                        >
                            <div
                                class="flex flex-row h-full w-full justify-between p-2"
                            >
                                <template v-if="albumScopeStore?.loading">
                                    <div class="flex items-center">
                                        <ProgressSpinner />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="flex flex-col w-1/2 px-2">
                                        <div
                                            class="font-medium text-3xl text-900 mb-2"
                                        >
                                            Album Information
                                        </div>
                                        <div class="mb-3">
                                            <p
                                                v-if="!selectedAlbum?.id"
                                                class="text-500 mb-2"
                                            >
                                                A new album will be created with
                                                the following details:
                                            </p>
                                            <p v-else class="text-500 mb-2">
                                                You are adding the following
                                                photos to the album:
                                            </p>
                                        </div>
                                        <ul class="list-none p-0 m-0">
                                            <li
                                                class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
                                            >
                                                <div
                                                    class="text-500 w-4/12 font-medium"
                                                >
                                                    Album
                                                </div>
                                                <div
                                                    class="text-900 md:flex-order-0 flex-order-1 ml-2"
                                                >
                                                    {{ selectedAlbum?.name }}
                                                </div>
                                            </li>
                                            <li
                                                class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
                                            >
                                                <div
                                                    class="text-500 w-4/12 font-medium"
                                                >
                                                    Photos Adding
                                                </div>
                                                <div
                                                    class="text-900 md:flex-order-0 flex-order-1 ml-2"
                                                >
                                                    {{
                                                        photosToBeAddedToAlbum.length
                                                    }}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="flex flex-col w-1/2 h-full">
                                        <Galleria
                                            :value="photosToBeAddedToAlbum"
                                            :numVisible="5"
                                            :circular="true"
                                            :showItemNavigators="true"
                                            :showItemNavigatorsOnHover="true"
                                            container-class="h-full m-auto w-full"
                                            :pt="{
                                                content: {
                                                    class: ['m-auto h-full'],
                                                },
                                                itemsContainer: {
                                                    class: ['flex-grow'],
                                                },
                                            }"
                                        >
                                            <template #item="slotProps">
                                                <img
                                                    :src="
                                                        getCdnUrl(
                                                            slotProps.item
                                                                .thumbnailPath
                                                        )
                                                    "
                                                    :alt="slotProps.item.id"
                                                    style="
                                                        height: 270px;
                                                        display: block;
                                                    "
                                                />
                                            </template>
                                            <template #thumbnail="slotProps">
                                                <img
                                                    :src="
                                                        getCdnUrl(
                                                            slotProps.item
                                                                .thumbnailPath
                                                        )
                                                    "
                                                    width="50"
                                                    height="50"
                                                    :alt="slotProps.item.id"
                                                    style="display: block"
                                                />
                                            </template>
                                        </Galleria>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="pt-3 justify-between flex">
                            <Button
                                label="Confirm"
                                severity="primary"
                                icon="pi pi-check"
                                class="ml-auto"
                                @click="confirmAlbum()"
                            />
                        </div>
                    </div>
                </StepPanel>
            </StepPanels>
        </Stepper>
    </div>
</template>

<style>
.album-data-view .p-dataview-content {
    height: 100%;
}
</style>
