<script lang="ts" setup>
import { useScopedEventStore } from '@stores/EventStore.ts'
import { onMounted, onUnmounted } from 'vue'
import router from '@/router.ts'
import EventView from '../../../layouts/authenticated/events/BaseEvent.vue'
import Gallery from '@components/Gallery.vue'

const props = defineProps({
    eventId: String,
})

const eventStore = useScopedEventStore(props.eventId ?? '')

onMounted(async () => {
    if (!props.eventId) {
        await router.push('/')
        return
    }

    await eventStore.fetchEvent()

    if (!eventStore.event) {
        await router.push('/')
        return
    }

    await eventStore.fetchEventPhotos()
    eventStore.subscribeToPhotos()

    document.title = `Gallery - ${eventStore.event.name} - Snap Pix`
})

onUnmounted(() => {
    if (props.eventId) {
        eventStore.unsubscribeToPhotos()
    }
})
</script>

<template>
    <template v-if="eventStore.event">
        <EventView :event="eventStore.event">
            <div class="my-16 text-center">
                <div class="text-900 text-3xl font-medium mb-3">GALLERY</div>
            </div>
            <Gallery
                :event="eventStore.event"
                :images="eventStore.eventPhotos"
            />
            <router-view v-slot="{ Component, route }">
                <Transition name="fade">
                    <component
                        :is="Component"
                        :key="route.path"
                        :event="eventStore.event"
                        :images="eventStore.eventPhotos"
                    />
                </Transition>
            </router-view>
        </EventView>
    </template>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
