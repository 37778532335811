<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue'
import { EventModel } from '@models/EventModel.ts'
import {
    AlbumAllPhotos,
    AlbumFavourites,
    AlbumNavigation,
    AlbumView,
} from '@components/authenticated/events/albums'
import { Album, EventPhoto } from '@/api.ts'
import { useEventScopedAlbumStore } from '@stores/AlbumStore.ts'
import { storeToRefs } from 'pinia'
import AddToAlbum from '@components/authenticated/events/albums/AddToAlbum.vue'

type EventAlbumProps = {
    event: EventModel
    images: EventPhoto[]
}

const props = defineProps<EventAlbumProps>()

const event = props.event

const albumStore = useEventScopedAlbumStore(props.event.eventId)

const { albums } = storeToRefs(albumStore)

type view = {
    name: string
    icon: string
    component: typeof AlbumAllPhotos | typeof AlbumFavourites | typeof AlbumView
    children?: Ref<Album[]>
}

const views: view[] = [
    {
        name: 'All Photos',
        icon: 'pi-image',
        component: AlbumAllPhotos,
    },
    {
        name: 'Favourites',
        icon: 'pi-heart',
        component: AlbumFavourites,
    },
    {
        name: 'Albums',
        icon: 'pi-image',
        component: AlbumView,
        children: albums,
    },
]

type activeViewType = {
    component: typeof AlbumAllPhotos | typeof AlbumFavourites | typeof AlbumView
    data?: { name: string }
}

const activeView = ref<activeViewType>({
    component: AlbumAllPhotos,
    data: { name: 'album all photos' },
})

const handleViewClicked = (viewName: string, data?: { name: string }) => {
    const foundView = views.find((v) => v.name === viewName)
    if (!foundView) {
        return
    }

    activeView.value = {
        component: foundView.component,
        data: data,
    }
}

onMounted(async () => {
    document.title = `Download Photos - ${event.name} - Snap Pix`
    await albumStore.fetchAlbums()
})

function selectAllPhotos() {
    albumStore.selectAllPhotos()
}

function clearSelectedPhotos() {
    albumStore.clearSelectedPhotos()
}

const addToAlbumVisible = ref(false)
</script>

<template>
    <template v-if="event">
        <div
            class="min-h-dvh flex surface-ground fixed left-0 top-0 z-20 w-full flex-row"
        >
            <AddToAlbum
                class="absolute top-0 left-0 w-full h-full z-30"
                :visible="addToAlbumVisible"
                :event-id="props.event.eventId"
                @update:visible="addToAlbumVisible = $event"
            />
            <AlbumNavigation :views="views" @view-clicked="handleViewClicked" />
            <div class="h-dvh flex flex-column relative flex-auto">
                <div
                    class="flex justify-content-between align-items-center px-5 py-3 border-bottom-1 relative lg:static bg-amber-300 border-amber-500"
                >
                    <div class="flex">
                        <div>
                            <a
                                v-ripple
                                v-styleclass="{
                                    selector: '#album-sidebar',
                                    enterFromClass: 'hidden',
                                    enterActiveClass: 'fadeinleft',
                                    leaveToClass: 'hidden',
                                    leaveActiveClass: 'fadeoutleft',
                                    hideOnOutsideClick: true,
                                }"
                                class="lg:hidden cursor-pointer block text-stone-800 mr-3 mt-1 p-ripple"
                            >
                                <i class="pi pi-bars text-3xl"></i>
                            </a>
                        </div>
                        <span class="font-bold">All Photos</span> ({{
                            props.images?.length
                        }})
                    </div>
                    <div class="flex gap-2">
                        <div>
                            <Button @click="selectAllPhotos">
                                Select All
                            </Button>
                        </div>
                        <div>
                            <Button @click="clearSelectedPhotos">
                                Unselect All
                            </Button>
                        </div>
                        <div class="ml-3">
                            <router-link
                                v-ripple
                                :to="{ name: 'EventGallery' }"
                                class="cursor-pointer block text-stone-800 mt-1 p-ripple"
                            >
                                <i class="pi pi-times text-3xl"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <component
                    :is="activeView.component"
                    :event="event"
                    :data="activeView.data"
                />
                <div
                    class="bottom-0 w-full p-4 bg-amber-300 border-top-1 border-amber-500 flex align-items-center justify-content-end"
                >
                    <span
                        >{{ albumStore.selectedPhotosCount }} Photos Selected
                    </span>
                    <Button class="ml-3" @click="addToAlbumVisible = true"
                        >Add To Album</Button
                    >
                </div>
            </div>
        </div>
    </template>
</template>

<style scoped></style>
