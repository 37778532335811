<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import router from '../router.ts'
import { useAuthenticator } from '@aws-amplify/ui-vue'
import { getCurrentUser } from 'aws-amplify/auth'

const auth = useAuthenticator()
const username = ref<string | undefined>('')

const handleSignOut = async () => {
    await auth.signOut()
    await router.push({ name: 'Home' })
}

onMounted(async () => {
    const currentUser = await getCurrentUser()
    username.value = currentUser.signInDetails?.loginId
})
</script>

<template>
    <div
        class="min-h-dvh flex relative lg:static flex-row"
        style="
            background: url('/bokeh-background.jpg') no-repeat;
            background-size: cover;
            min-height: 100vh;
        "
    >
        <div
            id="main-sidebar"
            class="h-dvh hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 select-none animation-duration-300 animation-ease-in-out bg-white-alpha-50 border-white-alpha-30"
            style="width: 280px; backdrop-filter: blur(10px)"
        >
            <div class="flex flex-column h-full">
                <div
                    class="flex align-items-center px-5 flex-shrink-0"
                    style="height: 60px"
                >
                    <img
                        alt="Image"
                        class="mr-0 lg:mr-3 mt-4"
                        height="60"
                        src="/logo_long.png"
                    />
                </div>
                <div class="overflow-y-auto mt-3">
                    <ul class="list-none p-3 m-0">
                        <li>
                            <ul class="list-none p-0 m-0 overflow-hidden">
                                <li>
                                    <router-link
                                        v-ripple
                                        :to="{ name: 'Home' }"
                                        class="flex align-items-center cursor-pointer p-3 text-stone-800 hover:bg-purple-50 transition-duration-150 transition-colors p-ripple"
                                        style="border-radius: 30px"
                                    >
                                        <i class="pi pi-home mr-2"></i>
                                        <span class="font-medium">Home</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                        v-ripple
                                        :to="{ name: 'EventList' }"
                                        class="flex align-items-center cursor-pointer p-3 text-stone-800 hover:bg-purple-50 transition-duration-150 transition-colors p-ripple"
                                        style="border-radius: 30px"
                                    >
                                        <i class="pi pi-th-large mr-2"></i>
                                        <span>Events</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="mt-auto border-top-1 border-white-alpha-40">
                    <ul
                        class="list-none p-2 m-0 hidden origin-bottom animation-duration-150 overflow-hidden animation-ease-in-out"
                    >
                        <li>
                            <router-link
                                v-ripple
                                :to="{ name: 'Profile' }"
                                class="flex align-items-center cursor-pointer p-3 text-stone-800 hover:bg-purple-50 transition-duration-150 transition-colors p-ripple"
                                style="border-radius: 30px"
                            >
                                <i class="pi pi-user mr-2"></i>
                                <span class="font-medium">Profile</span>
                            </router-link>
                        </li>
                        <li>
                            <Button
                                v-ripple
                                class="flex align-items-center cursor-pointer p-3 hover:bg-purple-50 text-stone-800 transition-duration-150 transition-colors p-ripple"
                                link
                                style="border-radius: 30px"
                                @click="handleSignOut"
                            >
                                <i class="pi pi-sign-out mr-2"></i>
                                <span class="font-medium">Sign Out</span>
                            </Button>
                        </li>
                    </ul>
                    <a
                        v-ripple
                        v-styleclass="{
                            selector: '@prev',
                            enterFromClass: 'hidden',
                            enterActiveClass: 'slidedown',
                            leaveToClass: 'hidden',
                            leaveActiveClass: 'slideup',
                        }"
                        class="m-3 px-3 py-2 flex align-items-center hover:bg-purple-50 text-stone-800 cursor-pointer text-stone-800 transition-duration-150 transition-colors p-ripple"
                        style="border-radius: 30px"
                    >
                        <span class="font-medium"> {{ username }} </span>
                        <i class="pi pi-chevron-up ml-auto"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="h-dvh flex flex-column relative flex-auto">
            <div
                class="flex justify-content-between align-items-center px-5 py-3 border-bottom-1 relative lg:static bg-white-alpha-50 border-white-alpha-40"
            >
                <div class="flex">
                    <a
                        v-ripple
                        v-styleclass="{
                            selector: '#main-sidebar',
                            enterFromClass: 'hidden',
                            enterActiveClass: 'fadeinleft',
                            leaveToClass: 'hidden',
                            leaveActiveClass: 'fadeoutleft',
                            hideOnOutsideClick: true,
                        }"
                        class="cursor-pointer block lg:hidden text-stone-800 mr-3 mt-1 p-ripple"
                    >
                        <i class="pi pi-bars text-4xl"></i>
                    </a>
                </div>
                <a
                    v-ripple
                    v-styleclass="{
                        selector: '@next',
                        enterFromClass: 'hidden',
                        enterActiveClass: 'fadein',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'fadeout',
                        hideOnOutsideClick: true,
                    }"
                    class="cursor-pointer block lg:hidden text-stone-800 p-ripple"
                >
                    <i class="pi pi-ellipsis-v text-2xl"></i>
                </a>
                <ul
                    class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static px-3 py-3 lg:py-0 mr-3 lg:mr-0"
                    style="backdrop-filter: blur(10px)"
                >
                    <li class="mr-0 lg:mr-3">
                        <a
                            v-ripple
                            class="flex p-3 lg:p-2 align-items-center text-stone-800 hover:bg-purple-50 font-medium cursor-pointer transition-duration-150 transition-colors p-ripple"
                            style="border-radius: 30px"
                        >
                            <i
                                class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"
                            ></i>
                            <span class="block lg:hidden font-medium"
                                >Inbox</span
                            >
                        </a>
                    </li>
                    <li class="mr-0 lg:mr-3">
                        <a
                            v-ripple
                            class="flex p-3 lg:p-2 align-items-center text-stone-800 hover:bg-purple-50 font-medium cursor-pointer transition-duration-150 transition-colors p-ripple"
                            style="border-radius: 30px"
                        >
                            <i
                                v-badge.danger
                                class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"
                            ></i>
                            <span class="block lg:hidden font-medium"
                                >Notifications</span
                            >
                        </a>
                    </li>
                    <li
                        class="border-top-1 lg:border-top-none lg:mt-0 mt-2 lg:pt-0 pt-2 border-white-alpha-40"
                    >
                        <a
                            v-ripple
                            class="flex p-3 lg:p-2 align-items-center hover:bg-purple-50 font-medium cursor-pointer transition-duration-150 transition-colors p-ripple"
                            style="border-radius: 30px"
                        >
                            <!--                            <img-->
                            <!--                                src="/images/blocks/avatars/circle/avatar-f-1.png"-->
                            <!--                                class="mr-3 lg:mr-0"-->
                            <!--                                style="width: 32px; height: 32px"-->
                            <!--                            />-->
                            <div class="block lg:hidden">
                                <div class="text-gray-900 font-medium">
                                    {{ username }}
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div
                class="flex flex-column flex-auto overflow-y-scroll lg:mt-5 lg:ml-5 lg:mr-5"
            >
                <div class="flex-auto bg-white-alpha-50 border-white-alpha-40">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>
