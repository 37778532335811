import { Event } from '../api.ts'
import new_event_image from '../assets/new_event_image.jpg'

export enum EventStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    ENDED = 'ENDED',
}

export interface ICaptureEventModel {
    eventId: string
    name: string
    description: string
    eventImage: string
    status: EventStatus
    startDateTime: Date
}

export class CaptureEventModel implements ICaptureEventModel {
    public readonly eventId: string
    public readonly name: string
    public readonly description: string

    private readonly _startDateTime: string
    private readonly _endDateTime: string
    private readonly _imageBase64: string

    private constructor(
        eventId: string,
        name: string,
        description: string,
        imageBase64: string,
        startDateTime: string,
        endDateTime: string
    ) {
        this.eventId = eventId
        this.name = name
        this.description = description
        this._imageBase64 = imageBase64
        this._startDateTime = startDateTime
        this._endDateTime = endDateTime
    }

    public get eventImage(): string {
        if (this._imageBase64) {
            return `data:image/png;base64,${this._imageBase64}`
        }
        return new_event_image
    }

    public get status(): EventStatus {
        if (new Date() < new Date(this._startDateTime)) {
            return EventStatus.NOT_STARTED
        } else if (new Date() > new Date(this._endDateTime)) {
            return EventStatus.ENDED
        }

        return EventStatus.IN_PROGRESS
    }

    public get startDateTime(): Date {
        return new Date(this._startDateTime)
    }

    public static fromDTO(dto: Event): CaptureEventModel {
        if (!dto) {
            throw new Error('Event DTO is required')
        }

        if (!dto.eventEndDateTime || !dto.eventStartDateTime) {
            throw new Error('Event start and end date times are required')
        }

        return new CaptureEventModel(
            dto.id,
            dto.name,
            dto.description ?? '',
            dto.imageBase64 ?? '',
            dto.eventStartDateTime,
            dto.eventEndDateTime
        )
    }
}
