<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, toRaw, watch } from 'vue'
import Webcam from '../components/Webcam.vue'
import Sidebar from 'primevue/sidebar'
import { useRoute } from 'vue-router'
import ProgressSpinner from 'primevue/progressspinner'
import { useCaptureEventStore } from '../stores/CaptureEventStore.ts'
import Countdown from '../components/Countdown.vue'
import { EventStatus } from '../models/CaptureEventModel.ts'
import { usePhotoStore } from '../stores/CapturePhotoStore.ts'
import { CapturePhotoModel } from '../models/CapturePhotoModel.ts'

const webcam = ref<typeof Webcam | undefined>(undefined)
const imageSrc = ref<string | undefined>(undefined)
const photosVisible = ref(true)
const currentImage = ref<CapturePhotoModel | null>(null)

const eventStore = useCaptureEventStore()
const photoStore = usePhotoStore()

let reloadCamInterval: NodeJS.Timeout
let cameras: MediaDeviceInfo[] = []
let currentDeviceId = ''

const sidebarVisible = ref(false)

const props = defineProps({
    eventId: String,
})

const route = useRoute()
const authKey = route.query.auth as string

const photoTakenEvent = async (photo: {
    blob: Blob
    image_data_url: string
}) => {
    const eventId = eventStore.event?.eventId

    if (!eventId) {
        return
    }

    await photoStore.createPhoto(eventId, currentDeviceId, photo)
}

const takePhoto = async () => {
    try {
        if (!webcam.value) {
            return
        }

        await webcam.value.takePhoto()
    } catch (err) {
        console.log(err)
    }
}

const switchCamera = () => {
    if (!webcam.value) {
        return
    }

    const nextCamera = cameras.find(
        (camera) => camera.deviceId !== currentDeviceId
    )

    if (nextCamera) {
        webcam.value.changeCamera(nextCamera.deviceId)
        currentDeviceId = nextCamera.deviceId
    }
}

const loadCameras = async () => {
    if (!webcam.value) {
        return
    }

    await webcam.value.loadCameras()
    cameras = toRaw(webcam.value.cameras)

    if (cameras.length > 0) {
        currentDeviceId = cameras[0].deviceId
        await webcam.value.changeCamera(cameras[0].deviceId)
    }
}

const getEvent = async () => {
    try {
        if (!props.eventId) {
            return
        }

        if (!authKey) {
            return
        }

        await eventStore.fetchEvent(props.eventId, authKey)
    } catch (e) {
        console.log('GET call failed: ', e)
    }
}

const onPhotoClick = (photo: CapturePhotoModel) => {
    currentImage.value = photo
}

const downloadCurrentImage = (photo: CapturePhotoModel) => {
    const link = document.createElement('a')
    link.href = photo.base64Image
    link.download = photo.eventId + '.png'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

onMounted(async () => {
    await getEvent()

    if (!eventStore.event) {
        return
    }

    await photoStore.fetchPhotos(eventStore.event.eventId)
})

onBeforeUnmount(() => {
    if (reloadCamInterval) {
        clearInterval(reloadCamInterval)
    }

    if (webcam.value) {
        webcam.value.stop()
    }
})

watch(webcam, async () => {
    if (webcam.value) {
        await loadCameras()

        if (cameras.length === 0) {
            // if no camera found, we will try to refresh cameras list each second until there is some camera
            reloadCamInterval = setInterval(() => {
                loadCameras()

                if (cameras.length > 0) {
                    clearInterval(reloadCamInterval)
                }
            }, 1000)
        }
    }
})
</script>

<template>
    <div class="bg-black px-0 py-0">
        <div class="px-0 py-0 h-dvh">
            <div class="bg-black h-full flex align-middle align-items-center">
                <template v-if="currentImage">
                    <div
                        class="fixed top-0 right-0 z-50 cursor-pointer p-3 hover:scale-105 transition-transform duration-300 ease-in-out"
                    >
                        <i
                            @click="currentImage = null"
                            class="pi pi-times text-4xl"
                        ></i>
                    </div>
                    <div
                        class="fixed top-0 left-0 z-50 cursor-pointer p-3 hover:scale-110 transition-transform duration-300 ease-in-out"
                    >
                        <Tag
                            value="Photo Uploaded"
                            severity="success"
                            v-if="currentImage?.uploaded"
                        ></Tag>
                        <Tag
                            value="Photo Not Uploaded"
                            severity="danger"
                            v-else
                        ></Tag>
                    </div>
                    <div class="fixed w-full h-full">
                        <img
                            :src="currentImage?.base64Image"
                            alt="Image"
                            class="w-full h-full object-contain"
                        />
                    </div>
                    <div
                        class="fixed bottom-0 right-0 z-50 cursor-pointer p-3 hover:scale-110 transition-transform duration-300 ease-in-out"
                    >
                        <i
                            @click="downloadCurrentImage(currentImage)"
                            class="pi pi-download text-4xl"
                        ></i>
                    </div>
                </template>
                <template v-if="!eventStore.isLoading && !eventStore.event">
                    <div
                        class="surface-card border-round flex shadow-2 m-6 flex-column-reverse md:flex-row align-items-center justify-content-center"
                    >
                        <div
                            class="flex align-items-center justify-content-center max-w-15rem"
                        >
                            <img
                                src="/event_not_found.svg"
                                alt="Image"
                                class="mx-auto block w-full"
                            />
                        </div>
                        <div
                            class="py-3 px-5 flex flex-column align-items-start max-w-30rem min-w-15rem"
                        >
                            <div class="text-900 font-medium mb-2 text-xl">
                                Event Not Found
                            </div>
                            <p class="mb-3 mt-2 p-0">
                                The event you are trying to access is not found.
                            </p>
                            <small>
                                If you used a QR code to access this page,
                                please contact the event organiser.
                            </small>
                        </div>
                    </div>
                </template>
                <template
                    v-if="
                        eventStore.event &&
                        eventStore.event.status === EventStatus.NOT_STARTED
                    "
                >
                    <div
                        class="flex flex-column align-items-center justify-center w-full"
                    >
                        <div class="py-3 px-5 flex justify-center">
                            <div
                                style="
                                    width: 140px;
                                    height: 140px;
                                    border-radius: 10px;
                                "
                                class="surface-card shadow-2"
                            >
                                <img
                                    :src="eventStore.event?.eventImage"
                                    alt="Image"
                                    width="140"
                                    height="140"
                                />
                            </div>
                        </div>
                        <div
                            class="py-3 px-5 flex flex-column align-items-start max-w-50rem min-w-15rem"
                        >
                            <div
                                class="text-900 font-medium mb-2 text-xl text-white text-center w-full"
                            >
                                {{ eventStore.event?.name }}
                            </div>
                            <div
                                class="text-900 font-normal mb-2 text-xl text-white text-center w-full"
                            >
                                Will begin in
                            </div>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center max-w-15rem"
                        >
                            <Countdown
                                :date="eventStore.event?.startDateTime"
                            />
                        </div>
                        <div
                            class="py-5 px-5 flex flex-column align-items-start max-w-30rem min-w-15rem text-center"
                        >
                            <small class="text-white">
                                If this event is currently ongoing speak to the
                                event organiser.
                            </small>
                        </div>
                    </div>
                </template>
                <template
                    v-if="
                        eventStore.event &&
                        eventStore.event.status === EventStatus.ENDED
                    "
                >
                    <div
                        class="surface-card border-round flex shadow-2 m-6 flex-column-reverse md:flex-row align-items-center justify-content-center"
                    >
                        <div
                            class="flex align-items-center justify-content-center max-w-15rem"
                        >
                            <img
                                src="/event_not_found.svg"
                                alt="Image"
                                class="mx-auto block w-full"
                            />
                        </div>
                        <div
                            class="py-3 px-5 flex flex-column align-items-start max-w-30rem min-w-15rem"
                        >
                            <div class="text-900 font-medium mb-2 text-xl">
                                Event Has Finished
                            </div>
                            <p class="mb-3 mt-2 p-0">
                                This event has finished!
                            </p>
                            <small>
                                If this event is still ongoing speak to the
                                event organiser.
                            </small>
                        </div>
                    </div>
                </template>
                <template v-if="eventStore.isLoading">
                    <ProgressSpinner />
                </template>
                <template
                    v-if="
                        eventStore.event &&
                        eventStore.event.status === EventStatus.IN_PROGRESS &&
                        !currentImage
                    "
                >
                    <Sidebar
                        v-model:visible="sidebarVisible"
                        header="Event Details"
                        class="w-full md:w-20rem lg:w-30rem"
                    >
                        <div
                            class="grid grid-nogutter border-top-1 surface-border pt-2"
                        >
                            <div class="col-12 py-4 px-3 flex justify-center">
                                <div
                                    style="
                                        width: 140px;
                                        height: 140px;
                                        border-radius: 10px;
                                    "
                                    class="surface-card shadow-2"
                                >
                                    <img
                                        :src="eventStore?.event.eventImage"
                                        alt="Image"
                                        width="140"
                                        height="140"
                                    />
                                </div>
                            </div>
                            <div class="col-12 py-2 px-3">
                                <div class="text-500 font-medium mb-2">
                                    Name
                                </div>
                                <div class="text-900">
                                    {{ eventStore.event?.name }}
                                </div>
                            </div>
                            <div class="col-12 py-2 px-3">
                                <div class="text-500 font-medium mb-2">
                                    Description
                                </div>
                                <div class="text-900">
                                    {{ eventStore.event?.description }}
                                </div>
                            </div>
                        </div>
                    </Sidebar>
                    <div class="h-full flex align-middle w-full">
                        <Webcam
                            ref="webcam"
                            @photoTaken="photoTakenEvent"
                            autoStart
                        />
                        <div
                            class="camera-photos fixed portrait:w-full portrait:bottom-32 flex text-white justify-between z-50 border-x-0"
                        >
                            <div
                                class="portrait:w-full align-content-center text-center landscape:mr-2 portrait:mb-1"
                            >
                                <i
                                    class="pi text-5xl pi-chevron-left"
                                    @click="photosVisible = !photosVisible"
                                    :class="
                                        photosVisible
                                            ? 'photo-pointer-open'
                                            : 'photo-pointer-closed'
                                    "
                                ></i>
                            </div>
                            <div
                                class="flex flex-nowrap overflow-x-scroll overflow-y-hidden"
                                v-if="photosVisible"
                            >
                                <template v-for="photo in photoStore.photos">
                                    <div
                                        class="image-container portrait:mr-1 landscape:mb-1 hover:scale-105 transition-transform duration-300 ease-in-out"
                                    >
                                        <img
                                            :src="photo.base64Image"
                                            width="100"
                                            height="100"
                                            class="object-cover"
                                            alt=""
                                            @click="onPhotoClick(photo)"
                                        />
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div
                            class="camera-buttons fixed portrait:w-full landscape:h-full portrait:bottom-0 landscape:right-0 flex text-white justify-between p-5 z-50"
                        >
                            <i
                                @click="sidebarVisible = true"
                                class="pi pi-info-circle text-5xl"
                            ></i>
                            <div
                                @click="takePhoto"
                                class="relative"
                                style="height: 50px; width: 50px"
                            >
                                <i
                                    class="pi pi-circle-fill text-4xl absolute"
                                    style="left: 8px; top: 8px"
                                ></i>
                                <i class="pi pi-circle text-6xl absolute"></i>
                            </div>
                            <i
                                class="pi pi-sync text-5xl"
                                @click="switchCamera"
                            ></i>
                        </div>
                    </div>
                    <img :src="imageSrc" class="mt-8 mb-4" v-if="imageSrc" />
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.camera-buttons {
}

.camera-photos {
    flex-direction: column;
}

.camera-photos > div {
    height: 100%;
    flex-direction: row;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
}

.photo-pointer-open {
    transform: rotate(270deg);
}

.photo-pointer-closed {
    transform: rotate(90deg);
}

.image-container {
    width: 100px;
    height: 100px;
}

@media (orientation: landscape) {
    .camera-buttons {
        bottom: 0;
        right: 0;
        flex-direction: column-reverse;
    }

    .camera-photos {
        bottom: 0;
        right: 8rem;
        flex-direction: row;
    }

    .camera-photos > div {
        height: 100vh !important;
        flex-direction: column-reverse;
        overflow-x: hidden !important;
        overflow-y: scroll !important;
    }

    .photo-pointer-open {
        transform: rotate(180deg);
    }

    .photo-pointer-closed {
        transform: rotate(0deg);
    }
}
</style>
