<script setup lang="ts">
import { useAuthenticator } from '@aws-amplify/ui-vue'
import { ref, toRefs } from 'vue'
// `route` `user` and `signOut` all are reactive.
const { signOut } = toRefs(useAuthenticator())

const activeTab = ref(0)
</script>

<template>
    <div class="flex flex-column flex-auto bg-black-alpha-90">
        <div class="surface-section px-4 py-4 lg:py-5 lg:px-6 h-full">
            <div
                class="flex flex-column md:flex-row w-full justify-content-between md:align-items-center"
            >
                <div>
                    <h2 class="mt-0 mb-2 text-900 font-medium text-2xl">
                        Settings
                    </h2>
                    <p class="mt-0 mb-0 text-500">Your account settings</p>
                </div>
                <span
                    class="p-input-icon-right w-full mt-2 md:mt-0 md:w-15rem flex justify-end"
                >
                    <Button @click="signOut">Sign Out</Button>
                </span>
            </div>
            <Divider styleClass="my-5"></Divider>
            <div class="p-fluid flex flex-column lg:flex-row">
                <ul
                    class="list-none m-0 p-0 flex flex-row lg:flex-column justify-content-between lg:justify-content-start mb-5 lg:mb-0"
                >
                    <li>
                        <a
                            v-ripple
                            class="p-ripple lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors"
                            :class="{ 'surface-200': activeTab === 0 }"
                            @click="activeTab = 0"
                        >
                            <i
                                class="pi pi-user md:mr-2"
                                :class="{
                                    'text-700': activeTab === 0,
                                    'text-600': activeTab !== 0,
                                }"
                            ></i>
                            <span
                                class="font-medium hidden md:block"
                                :class="{
                                    'text-800': activeTab === 0,
                                    'text-700': activeTab !== 0,
                                }"
                                >Profile</span
                            >
                        </a>
                    </li>
                </ul>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
