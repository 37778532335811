/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../api'
type GeneratedSubscription<InputType, OutputType> = string & {
    __generatedSubscriptionInput: InputType
    __generatedSubscriptionOutput: OutputType
}

export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onCreateUser(filter: $filter, owner: $owner) {
    id
    owner
    name
    email
    phone
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateUserSubscriptionVariables,
    APITypes.OnCreateUserSubscription
>
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onUpdateUser(filter: $filter, owner: $owner) {
    id
    owner
    name
    email
    phone
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateUserSubscriptionVariables,
    APITypes.OnUpdateUserSubscription
>
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onDeleteUser(filter: $filter, owner: $owner) {
    id
    owner
    name
    email
    phone
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteUserSubscriptionVariables,
    APITypes.OnDeleteUserSubscription
>
export const onCreateTransaction =
    /* GraphQL */ `subscription OnCreateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
  $owner: String
) {
  onCreateTransaction(filter: $filter, owner: $owner) {
    id
    owner
    amount
    associatedId
    transactionType
    transactionDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnCreateTransactionSubscriptionVariables,
        APITypes.OnCreateTransactionSubscription
    >
export const onUpdateTransaction =
    /* GraphQL */ `subscription OnUpdateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
  $owner: String
) {
  onUpdateTransaction(filter: $filter, owner: $owner) {
    id
    owner
    amount
    associatedId
    transactionType
    transactionDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnUpdateTransactionSubscriptionVariables,
        APITypes.OnUpdateTransactionSubscription
    >
export const onDeleteTransaction =
    /* GraphQL */ `subscription OnDeleteTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
  $owner: String
) {
  onDeleteTransaction(filter: $filter, owner: $owner) {
    id
    owner
    amount
    associatedId
    transactionType
    transactionDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnDeleteTransactionSubscriptionVariables,
        APITypes.OnDeleteTransactionSubscription
    >
export const onCreateEvent = /* GraphQL */ `subscription OnCreateEvent(
  $filter: ModelSubscriptionEventFilterInput
  $owner: String
) {
  onCreateEvent(filter: $filter, owner: $owner) {
    id
    name
    owner
    description
    guestMessage
    venueId
    venue {
      id
      name
      address
      city
      county
      country
      postcode
      createdAt
      updatedAt
      __typename
    }
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    albums {
      nextToken
      __typename
    }
    transactionId
    transaction {
      id
      owner
      amount
      associatedId
      transactionType
      transactionDate
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateEventSubscriptionVariables,
    APITypes.OnCreateEventSubscription
>
export const onUpdateEvent = /* GraphQL */ `subscription OnUpdateEvent(
  $filter: ModelSubscriptionEventFilterInput
  $owner: String
) {
  onUpdateEvent(filter: $filter, owner: $owner) {
    id
    name
    owner
    description
    guestMessage
    venueId
    venue {
      id
      name
      address
      city
      county
      country
      postcode
      createdAt
      updatedAt
      __typename
    }
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    albums {
      nextToken
      __typename
    }
    transactionId
    transaction {
      id
      owner
      amount
      associatedId
      transactionType
      transactionDate
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateEventSubscriptionVariables,
    APITypes.OnUpdateEventSubscription
>
export const onDeleteEvent = /* GraphQL */ `subscription OnDeleteEvent(
  $filter: ModelSubscriptionEventFilterInput
  $owner: String
) {
  onDeleteEvent(filter: $filter, owner: $owner) {
    id
    name
    owner
    description
    guestMessage
    venueId
    venue {
      id
      name
      address
      city
      county
      country
      postcode
      createdAt
      updatedAt
      __typename
    }
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    albums {
      nextToken
      __typename
    }
    transactionId
    transaction {
      id
      owner
      amount
      associatedId
      transactionType
      transactionDate
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteEventSubscriptionVariables,
    APITypes.OnDeleteEventSubscription
>
export const onCreateVenue =
    /* GraphQL */ `subscription OnCreateVenue($filter: ModelSubscriptionVenueFilterInput) {
  onCreateVenue(filter: $filter) {
    id
    name
    address
    city
    county
    country
    postcode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnCreateVenueSubscriptionVariables,
        APITypes.OnCreateVenueSubscription
    >
export const onUpdateVenue =
    /* GraphQL */ `subscription OnUpdateVenue($filter: ModelSubscriptionVenueFilterInput) {
  onUpdateVenue(filter: $filter) {
    id
    name
    address
    city
    county
    country
    postcode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnUpdateVenueSubscriptionVariables,
        APITypes.OnUpdateVenueSubscription
    >
export const onDeleteVenue =
    /* GraphQL */ `subscription OnDeleteVenue($filter: ModelSubscriptionVenueFilterInput) {
  onDeleteVenue(filter: $filter) {
    id
    name
    address
    city
    county
    country
    postcode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnDeleteVenueSubscriptionVariables,
        APITypes.OnDeleteVenueSubscription
    >
export const onCreateEventPhoto =
    /* GraphQL */ `subscription OnCreateEventPhoto(
  $filter: ModelSubscriptionEventPhotoFilterInput
  $owner: String
) {
  onCreateEventPhoto(filter: $filter, owner: $owner) {
    id
    owner
    eventId
    event {
      id
      name
      owner
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      transactionId
      createdAt
      updatedAt
      __typename
    }
    imagePath
    thumbnailPath
    height
    width
    albumPhotos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnCreateEventPhotoSubscriptionVariables,
        APITypes.OnCreateEventPhotoSubscription
    >
export const onUpdateEventPhoto =
    /* GraphQL */ `subscription OnUpdateEventPhoto(
  $filter: ModelSubscriptionEventPhotoFilterInput
  $owner: String
) {
  onUpdateEventPhoto(filter: $filter, owner: $owner) {
    id
    owner
    eventId
    event {
      id
      name
      owner
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      transactionId
      createdAt
      updatedAt
      __typename
    }
    imagePath
    thumbnailPath
    height
    width
    albumPhotos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnUpdateEventPhotoSubscriptionVariables,
        APITypes.OnUpdateEventPhotoSubscription
    >
export const onDeleteEventPhoto =
    /* GraphQL */ `subscription OnDeleteEventPhoto(
  $filter: ModelSubscriptionEventPhotoFilterInput
  $owner: String
) {
  onDeleteEventPhoto(filter: $filter, owner: $owner) {
    id
    owner
    eventId
    event {
      id
      name
      owner
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      transactionId
      createdAt
      updatedAt
      __typename
    }
    imagePath
    thumbnailPath
    height
    width
    albumPhotos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnDeleteEventPhotoSubscriptionVariables,
        APITypes.OnDeleteEventPhotoSubscription
    >
export const onCreateAlbum = /* GraphQL */ `subscription OnCreateAlbum(
  $filter: ModelSubscriptionAlbumFilterInput
  $owner: String
) {
  onCreateAlbum(filter: $filter, owner: $owner) {
    id
    eventId
    event {
      id
      name
      owner
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      transactionId
      createdAt
      updatedAt
      __typename
    }
    owner
    name
    description
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateAlbumSubscriptionVariables,
    APITypes.OnCreateAlbumSubscription
>
export const onUpdateAlbum = /* GraphQL */ `subscription OnUpdateAlbum(
  $filter: ModelSubscriptionAlbumFilterInput
  $owner: String
) {
  onUpdateAlbum(filter: $filter, owner: $owner) {
    id
    eventId
    event {
      id
      name
      owner
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      transactionId
      createdAt
      updatedAt
      __typename
    }
    owner
    name
    description
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateAlbumSubscriptionVariables,
    APITypes.OnUpdateAlbumSubscription
>
export const onDeleteAlbum = /* GraphQL */ `subscription OnDeleteAlbum(
  $filter: ModelSubscriptionAlbumFilterInput
  $owner: String
) {
  onDeleteAlbum(filter: $filter, owner: $owner) {
    id
    eventId
    event {
      id
      name
      owner
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      transactionId
      createdAt
      updatedAt
      __typename
    }
    owner
    name
    description
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteAlbumSubscriptionVariables,
    APITypes.OnDeleteAlbumSubscription
>
export const onCreateAlbumPhoto =
    /* GraphQL */ `subscription OnCreateAlbumPhoto(
  $filter: ModelSubscriptionAlbumPhotoFilterInput
  $owner: String
) {
  onCreateAlbumPhoto(filter: $filter, owner: $owner) {
    id
    owner
    albumId
    album {
      id
      eventId
      owner
      name
      description
      createdAt
      updatedAt
      __typename
    }
    image {
      id
      owner
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    eventPhotoAlbumPhotosId
    albumPhotosId
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnCreateAlbumPhotoSubscriptionVariables,
        APITypes.OnCreateAlbumPhotoSubscription
    >
export const onUpdateAlbumPhoto =
    /* GraphQL */ `subscription OnUpdateAlbumPhoto(
  $filter: ModelSubscriptionAlbumPhotoFilterInput
  $owner: String
) {
  onUpdateAlbumPhoto(filter: $filter, owner: $owner) {
    id
    owner
    albumId
    album {
      id
      eventId
      owner
      name
      description
      createdAt
      updatedAt
      __typename
    }
    image {
      id
      owner
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    eventPhotoAlbumPhotosId
    albumPhotosId
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnUpdateAlbumPhotoSubscriptionVariables,
        APITypes.OnUpdateAlbumPhotoSubscription
    >
export const onDeleteAlbumPhoto =
    /* GraphQL */ `subscription OnDeleteAlbumPhoto(
  $filter: ModelSubscriptionAlbumPhotoFilterInput
  $owner: String
) {
  onDeleteAlbumPhoto(filter: $filter, owner: $owner) {
    id
    owner
    albumId
    album {
      id
      eventId
      owner
      name
      description
      createdAt
      updatedAt
      __typename
    }
    image {
      id
      owner
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    eventPhotoAlbumPhotosId
    albumPhotosId
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnDeleteAlbumPhotoSubscriptionVariables,
        APITypes.OnDeleteAlbumPhotoSubscription
    >
