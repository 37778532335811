import { defineStore } from 'pinia'
import { Event, EventPhoto } from '../api.ts'
import {
    eventPhotosByEventId,
    getEvent,
    listEvents,
} from '../graphql/queries.ts'
import { generateClient } from 'aws-amplify/api'
import { createEvent, deleteEvent, updateEvent } from '../graphql/mutations.ts'
import { v4 as uuidv4 } from 'uuid'
import { EventModel, IEventModel } from '../models/EventModel.ts'
import * as subscriptions from '../graphql/subscriptions'
import { Subscription } from 'rxjs'

interface EventStoreState {
    events: IEventModel[]
    isLoading: boolean
}

const client = generateClient({
    authMode: 'userPool',
})

export const useEventStore = defineStore('EventStore', {
    state: (): EventStoreState => ({
        /** @type: EventModel[] */
        events: [],
        isLoading: false,
    }),
    getters: {
        count: (state: EventStoreState) => state.events.length,
        getEventById: (state: EventStoreState) => (eventId: string) => {
            return state.events.find((event) => event.eventId === eventId)
        },
    },
    actions: {
        async createEvent(name: string, description: string) {
            const result = await client.graphql({
                query: createEvent,
                variables: {
                    input: {
                        id: uuidv4().toString(),
                        name: name,
                        description: description,
                    },
                },
            })

            const newEvent = result.data.createEvent as Event

            this.events = [EventModel.fromDTO(newEvent), ...this.events]

            return newEvent
        },
        async fetchEvents() {
            this.isLoading = true

            const result = await client.graphql({ query: listEvents })

            this.events = result.data.listEvents.items
                .sort((a: Event, b: Event) => {
                    return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                    )
                })
                .map((x) => EventModel.fromDTO(x))

            this.isLoading = false
        },
        async fetchUpcomingEvents() {
            this.isLoading = true

            try {
                const currentDate = new Date()
                const currentDateISOString = currentDate.toISOString()
                const eventStartDate = new Date(
                    currentDate.setDate(currentDate.getDate() + 7)
                ).toISOString()

                const filter = {
                    eventStartDateTime: {
                        between: [currentDateISOString, eventStartDate],
                    },
                }

                const result = await client.graphql({
                    query: listEvents,
                    variables: { filter },
                })

                this.events = result.data.listEvents.items
                    .sort((a: Event, b: Event) => {
                        return (
                            new Date(b.createdAt).getTime() -
                            new Date(a.createdAt).getTime()
                        )
                    })
                    .map((x: Event) => EventModel.fromDTO(x))
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
        async fetchRecentlyEndedEvents() {
            this.isLoading = true

            try {
                const currentDate = new Date()
                const currentDateISOString = currentDate.toISOString()
                const eventEndDate = new Date(
                    currentDate.setDate(currentDate.getDate() - 7)
                ).toISOString()

                const filter = {
                    eventEndDateTime: {
                        between: [eventEndDate, currentDateISOString],
                    },
                }

                const result = await client.graphql({
                    query: listEvents,
                    variables: { filter },
                })

                this.events = result.data.listEvents.items
                    .sort((a: Event, b: Event) => {
                        return (
                            new Date(b.createdAt).getTime() -
                            new Date(a.createdAt).getTime()
                        )
                    })
                    .map((x: Event) => EventModel.fromDTO(x))
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
    },
})

interface EventStoreScopedState {
    event: IEventModel | undefined
    eventPhotos: EventPhoto[]
    eventPhotoSubscription: Subscription | undefined
    eventLoading: boolean
    photosLoading: boolean
}

export function useScopedEventStore(eventId: string) {
    const store = defineStore(`feature/events::${eventId}`, {
        state: (): EventStoreScopedState => ({
            event: undefined,
            eventPhotos: [],
            eventPhotoSubscription: undefined,
            eventLoading: false,
            photosLoading: false,
        }),
        getters: {
            eventPhotosCount: (state) => state.eventPhotos.length,
        },
        actions: {
            async updateEvent(
                eventId: string,
                name: string,
                description: string,
                startDate: string,
                endDate: string,
                authenticationKey: string
            ) {
                const response = await client.graphql({
                    query: updateEvent,
                    variables: {
                        input: {
                            id: eventId,
                            name: name,
                            description: description,
                            eventStartDateTime: startDate,
                            eventEndDateTime: endDate,
                            authenticationKey: authenticationKey,
                        },
                    },
                })

                const eventModel = EventModel.fromDTO(
                    response.data.updateEvent as Event
                )

                this.event = eventModel

                return eventModel
            },
            async fetchEvent(): Promise<EventModel | undefined> {
                try {
                    this.eventLoading = true

                    const response = await client.graphql({
                        query: getEvent,
                        variables: { id: eventId },
                    })

                    const eventModel = EventModel.fromDTO(
                        response.data.getEvent as Event
                    )

                    this.event = eventModel

                    return eventModel
                } catch (error) {
                    console.error(error)
                    return undefined
                } finally {
                    this.eventLoading = false
                }
            },
            async fetchEventPhotos() {
                try {
                    this.photosLoading = true

                    const response = await client.graphql({
                        query: eventPhotosByEventId,
                        variables: { eventId: eventId },
                    })

                    console.log(response)

                    this.eventPhotos =
                        response.data.eventPhotosByEventId.items.sort(
                            (a: EventPhoto, b: EventPhoto) => {
                                return (
                                    new Date(b.createdAt).getTime() -
                                    new Date(a.createdAt).getTime()
                                )
                            }
                        )

                    return this.eventPhotos
                } catch (error) {
                    console.error(error)
                    return undefined
                } finally {
                    this.photosLoading = false
                }
            },
            async deleteEvent() {
                const result = await client.graphql({
                    query: deleteEvent,
                    variables: {
                        input: {
                            id: eventId,
                        },
                    },
                })

                if (result.data.deleteEvent) {
                    this.event = undefined
                } else {
                    console.error('Failed to delete event')
                    throw new Error('Failed to delete event')
                }
            },
            subscribeToPhotos() {
                if (this.eventPhotoSubscription) return

                const variables = {
                    filter: {
                        eventId: { eq: eventId },
                    },
                }

                this.eventPhotoSubscription = client
                    .graphql({
                        query: subscriptions.onCreateEventPhoto,
                        variables,
                    })
                    .subscribe({
                        next: ({ data }) => {
                            const newPhoto =
                                data.onCreateEventPhoto as EventPhoto
                            this.eventPhotos.splice(0, 0, newPhoto)
                        },
                        error: (error) => console.warn(error),
                    })
            },
            unsubscribeToPhotos() {
                if (this.eventPhotoSubscription)
                    this.eventPhotoSubscription.unsubscribe()
            },
        },
    })

    return store()
}
