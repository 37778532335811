export interface IPhotoModel {
    id: number
    eventId: string
    deviceId: string
    base64Image: string
    uploaded: boolean
    markUploaded(): void
}

export interface Photo {
    id?: number
    eventId: string
    deviceId: string
    base64: string
    uploaded: boolean
}

export class CapturePhotoModel implements IPhotoModel {
    public readonly id: number
    public readonly eventId: string
    public readonly deviceId: string
    public base64Image: string
    public uploaded: boolean

    private constructor(
        id: number,
        eventId: string,
        deviceId: string,
        base64Image: string,
        uploaded: boolean
    ) {
        this.id = id
        this.eventId = eventId
        this.deviceId = deviceId
        this.base64Image = base64Image
        this.uploaded = uploaded
    }

    public markUploaded(): void {
        this.uploaded = true
    }

    public static fromDTO(photo: Photo): CapturePhotoModel {
        return new CapturePhotoModel(
            photo.id ?? 0,
            photo.eventId,
            photo.deviceId,
            photo.base64,
            photo.uploaded
        )
    }
}
