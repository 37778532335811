<script setup lang="ts">
import Galleria from 'primevue/galleria'
import { EventPhoto } from '@/api'
import { ref, watch } from 'vue'
import { useEventScopedAlbumStore } from '@stores/AlbumStore'
import { GalleryType, IEventModel } from '@models/EventModel'

interface GalleryProps {
    event: IEventModel
    images: EventPhoto[] | undefined
    galleryType?: GalleryType
    selectable?: boolean
}

const props = defineProps<GalleryProps>()
const emit = defineEmits<{
    (e: 'imagesLoaded', imageCount: number): void
}>()

const albumStore = useEventScopedAlbumStore(props.event.eventId)

const getCdnUrl = (path: string) => {
    return `${import.meta.env.VITE_CDN_URL}/${path}`
}

const activeIndex = ref(0)

const displayGallery = ref(false)

const imageClick = (eventPhoto: EventPhoto, index: number) => {
    if (props.selectable) {
        albumStore.togglePhotoSelected(eventPhoto)
    } else {
        activeIndex.value = index
        displayGallery.value = true
    }
}

const isImageSelected = (image: EventPhoto) => {
    return props.selectable && albumStore.isPhotoSelected(image)
}

const addToAlbum = (image: EventPhoto) => {
    console.log('Add to album', image)
}

const addToFavourites = (image: EventPhoto) => {
    console.log('Add to favourites', image)
}

const onImageLoad = (event: Event) => {
    if (!props.selectable) {
        return
    }

    const wrapper = document.createElement('div')
    const image = event.target as HTMLImageElement
    const parent = image.parentNode
    const sibling = image.nextElementSibling

    let imageWidth = image.width

    if (imageWidth > 130) {
        imageWidth = 130
    }

    wrapper.style.position = 'relative'
    wrapper.style.margin = 'auto'
    wrapper.style.width = `${imageWidth}px`
    wrapper.style.height = `130px`

    // Append the image to the wrapper
    wrapper.appendChild(image)

    if (sibling) {
        wrapper.appendChild(sibling)
    }

    // Replace the image with the wrapper in the DOM
    parent?.appendChild(wrapper)
}

watch(
    () => props.images,
    (images) => {
        if (!images || images.length === 0) {
            return
        }

        emit('imagesLoaded', images.length)
    }
)
</script>

<template>
    <template v-if="props.images?.length === 0">
        <div
            class="flex flex-column flex-auto justify-content-center align-items-center"
        >
            <span class="text-2xl text-stone-800">No images to display</span>
        </div>
    </template>
    <template v-if="!selectable">
        <Galleria
            v-model:activeIndex="activeIndex"
            v-model:visible="displayGallery"
            :circular="true"
            :fullScreen="true"
            :numVisible="7"
            :showItemNavigators="true"
            :showThumbnails="true"
            :value="props.images"
            containerStyle="max-width: 50%"
        >
            <template #item="slotProps">
                <img
                    :src="getCdnUrl(slotProps.item.imagePath)"
                    alt="Image"
                    style="width: 100%; display: block"
                />
            </template>
            <template #thumbnail="slotProps">
                <img
                    :src="getCdnUrl(slotProps.item.thumbnailPath)"
                    alt="Image"
                    style="display: block"
                />
            </template>
        </Galleria>
    </template>
    <div class="flex flex-wrap">
        <template v-for="(image, index) of props.images" :key="index">
            <div
                :class="selectable ? 'w-auto' : 'w-1/2 md:w-1/4'"
                class="gallery-image relative items-center overflow-hidden text-center w-1/4"
            >
                <img
                    :src="getCdnUrl(image.thumbnailPath)"
                    alt="Image"
                    class="hover:opacity-75"
                    style="
                        cursor: pointer;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    "
                    @click="imageClick(image, index)"
                    @load="onImageLoad"
                />
                <div
                    :class="isImageSelected(image) ? 'block' : 'hidden'"
                    class="selected-check absolute bottom-0 left-0 m-2.5 bg-black border-white border-1 rounded-full opacity-85"
                >
                    <span class="text-white pi pi-check text-xl"></span>
                </div>
                <div
                    v-if="!selectable"
                    class="image-options absolute bottom-0 h-1/4 w-full bg-opacity-80 bg-gradient-to-b from-transparent to-black items-end p-1"
                >
                    <a
                        class="m-2.5 cursor-pointer"
                        @click="addToFavourites(image)"
                        title="Add to favourites"
                    >
                        <span class="text-white pi pi-heart text-2xl"> </span>
                    </a>
                    <a
                        class="m-2.5 cursor-pointer"
                        @click="addToAlbum(image)"
                        title="Add to album"
                    >
                        <span class="text-white pi pi-download text-2xl">
                        </span>
                    </a>
                </div>
            </div>
        </template>
    </div>
</template>

<style>
.image-options {
    display: none !important;
}

.gallery-image:hover > .image-options {
    display: flex !important;
}

.selected-check {
    width: 40px;
    height: 40px;
}

.selected-check > span {
    width: 21px;
    height: 21px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
</style>
