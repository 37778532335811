import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './layouts/App.vue'
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import StyleClass from 'primevue/styleclass'
import Ripple from 'primevue/ripple'
import router from './router'
import ToastService from 'primevue/toastservice'
import AmplifyVue from '@aws-amplify/ui-vue'
import ConfirmationService from 'primevue/confirmationservice'

import './index.css'
import './style.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import '@aws-amplify/ui-vue/styles.css'
import Aura from '@primevue/themes/aura'
import { getAmplifyResourceConfig } from './configuration'

import { Amplify } from 'aws-amplify'
import { definePreset } from '@primevue/themes'

const amberPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{amber.50}',
            100: '{amber.100}',
            200: '{amber.200}',
            300: '{amber.300}',
            400: '{amber.400}',
            500: '{amber.500}',
            600: '{amber.600}',
            700: '{amber.700}',
            800: '{amber.800}',
            900: '{amber.900}',
            950: '{amber.950}',
        },
    },
})

Amplify.configure(getAmplifyResourceConfig())

console.log('Amplify configured', Amplify.getConfig())

createApp(App)
    .use(PrimeVue, {
        theme: {
            preset: amberPreset,
            options: {
                prefix: 'p',
                darkModeSelector: '.dark-mode',
                cssLayer: {
                    name: 'primevue',
                    order: 'tailwind-base, tailwind-utilities, primevue',
                },
            },
        },
    })
    .use(router)
    .use(ToastService)
    .use(AmplifyVue)
    .use(createPinia())
    .use(ConfirmationService)
    .directive('styleclass', StyleClass)
    .directive('ripple', Ripple)
    .directive('tooltip', Tooltip)
    .mount('#app')
