<script setup lang="ts">
import Gallery from '@components/Gallery.vue'
import { EventModel, GalleryType } from '@models/EventModel.ts'
import { onMounted } from 'vue'
import { useScopedEventStore } from '@stores/EventStore.ts'

type AlbumAllPhotosProps = {
    event: EventModel
}

const props = defineProps<AlbumAllPhotosProps>()

const eventStore = useScopedEventStore(props.event.eventId)

onMounted(async () => {
    await eventStore.fetchEventPhotos()
    document.title = `Gallery - ${props.event.name} - Snap Pix`
})
</script>

<template>
    <template v-if="eventStore.photosLoading">
        <div
            class="flex flex-column flex-auto justify-content-center align-items-center"
        >
            <ProgressSpinner></ProgressSpinner>
        </div>
    </template>
    <template v-else>
        <div class="flex flex-column flex-auto overflow-y-scroll">
            <div class="flex-auto bg-white-alpha-50 border-white-alpha-40">
                <div class="m-5">
                    <Gallery
                        :event="props.event"
                        :images="eventStore.eventPhotos"
                        :selectable="true"
                        :show-options="true"
                        :gallery-type="GalleryType.UNFIFORM"
                    ></Gallery>
                </div>
            </div>
        </div>
    </template>
</template>

<style scoped></style>
