<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { useField, useForm } from 'vee-validate'
import { useScopedEventStore } from '@stores/EventStore.ts'
import { onMounted, ref } from 'vue'
import router from '@/router.ts'
import { IEventModel } from '@models/EventModel.ts'
import Divider from 'primevue/divider'
import InputGroup from 'primevue/inputgroup'
import InputText from 'primevue/inputtext'
import FileUpload from 'primevue/fileupload'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import DatePicker from 'primevue/datepicker'

const props = defineProps({
    eventId: String,
})

const confirm = useConfirm()
const toast = useToast()

const confirmUpdateAuthorisationCode = () => {
    confirm.require({
        message:
            'This will cause any previous QR code downloads to be invalidated.',
        header: 'Update Authorisation Code',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary p-button-outlined',
        rejectLabel: 'Cancel',
        acceptLabel: 'Save',
        accept: () => {
            authenticationCode.value = Math.random()
                .toString(36)
                .substring(2, 25)
        },
        reject: () => {
            toast.add({
                severity: 'error',
                summary: 'Rejected',
                detail: 'Authorisation code not updated.',
                life: 3000,
            })
        },
    })
}

const eventStore = useScopedEventStore(props.eventId ?? '')
const eventRef = ref<IEventModel | null>(null)

const validationSchema = toTypedSchema(
    zod.object({
        name: zod.string().trim().min(3).max(50),
        description: zod.string().trim().min(3).max(250),
        startDate: zod.date(),
        endDate: zod.date(),
        authenticationCode: zod.string().trim().min(3).max(50),
    })
)

const { handleSubmit, errors } = useForm({
    validationSchema,
})

const { value: name } = useField<string>('name')
const { value: description } = useField<string>('description')
const { value: startDate } = useField<Date>('startDate')
const { value: endDate } = useField<Date>('endDate')
const { value: authenticationCode } = useField<string>('authenticationCode')

const onSubmit = handleSubmit(
    async ({ name, description, endDate, startDate, authenticationCode }) => {
        try {
            await eventStore.updateEvent(
                eventRef.value!.eventId,
                name,
                description,
                startDate.toISOString(),
                endDate.toISOString(),
                authenticationCode
            )

            toast.add({
                severity: 'success',
                summary: 'Event Updated',
                detail: 'Your event has been updated.',
                life: 3000,
            })

            await router.push(`/events/${props.eventId}`)
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Rejected',
                detail: 'Error updating event. Please try again.',
                life: 3000,
            })
        }
    }
)

onMounted(async () => {
    if (!props.eventId) {
        await router.push('/')
        return
    }

    const event = eventStore.event ?? (await eventStore.fetchEvent())

    if (!event) {
        await router.push('/')
        return
    }

    eventRef.value = event
    document.title = `Edit - ${event.name} - Snap Pix`

    name.value = event.name
    description.value = event.description
    if (event.startDateTime) startDate.value = new Date(event.startDateTime)
    if (event.endDateTime) endDate.value = new Date(event.endDateTime)
    authenticationCode.value = event.authenticationKey
})
</script>

<template>
    <div class="px-4 py-4">
        <div class="text-900 font-medium text-xl mb-3">Event Settings</div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            Use this page to manage your event settings and set up your event as
            you like.
        </p>
        <div class="surface-card p-4 shadow-2 border-round" v-if="eventRef">
            <form id="eventDetailsForm" @submit.prevent="onSubmit">
                <div class="grid formgrid p-fluid">
                    <div class="field mb-4 col-12 flex flex-col gap-2">
                        <label for="name" class="font-medium text-900"
                            >Name</label
                        >
                        <InputText id="name" type="text" v-model="name" />
                        <InlineMessage v-if="errors.name" class="mt-3"
                            >{{ errors.name }}
                        </InlineMessage>
                    </div>
                    <div class="field mb-4 col-12 md:col-6 flex flex-col gap-2">
                        <label for="description" class="font-medium text-900"
                            >Description</label
                        >
                        <Textarea
                            id="description"
                            type="text"
                            :rows="5"
                            :autoResize="true"
                            v-model="description"
                        ></Textarea>
                        <InlineMessage v-if="errors.description" class="mt-3"
                            >{{ errors.description }}
                        </InlineMessage>
                    </div>
                    <Divider />
                    <div class="field mb-4 col-12 md:col-6 flex flex-col gap-2">
                        <label for="eventImage" class="font-medium text-900"
                            >Event Image</label
                        >
                        <div
                            class="flex align-items-center flex-column md:flex-row"
                        >
                            <img
                                :src="eventRef.eventImage"
                                class="mr-0 md:mr-4"
                                height="200"
                            />
                            <FileUpload
                                id="eventImage"
                                mode="basic"
                                name="eventImage"
                                url="./upload.php"
                                accept="image/*"
                                :maxFileSize="1000000"
                                class="flex p-button-outlined p-button-plain mt-3 md:mt-0 m-0"
                                chooseLabel="Upload Image"
                            ></FileUpload>
                        </div>
                    </div>
                    <Divider />
                    <div class="field mb-4 col-12 md:col-6 flex flex-col gap-2">
                        <label for="startdatetime" class="font-medium text-900"
                            >Start Date & Time</label
                        >
                        <DatePicker
                            id="startdatetime"
                            v-model="startDate"
                            showIcon
                            iconDisplay="input"
                            showTime
                            hourFormat="24"
                        />
                        <InlineMessage v-if="errors.startDate" class="mt-3"
                            >{{ errors.startDate }}
                        </InlineMessage>
                    </div>
                    <div class="field mb-4 col-12 md:col-6 flex flex-col gap-2">
                        <label for="enddatetime" class="font-medium text-900"
                            >End Date & Time</label
                        >
                        <DatePicker
                            id="enddatetime"
                            v-model="endDate"
                            showIcon
                            iconDisplay="input"
                            showTime
                            hourFormat="24"
                        />
                        <InlineMessage v-if="errors.endDate" class="mt-3"
                            >{{ errors.endDate }}
                        </InlineMessage>
                    </div>
                    <Divider />
                    <div class="field mb-4 col-12 flex flex-col gap-2">
                        <label
                            for="authenticationKe"
                            class="font-medium text-900"
                            >Authentication Key</label
                        >
                        <InputGroup>
                            <InputText
                                id="authenticationKey"
                                disabled
                                type="text"
                                v-model="authenticationCode"
                            />
                            <Button
                                icon="pi pi-sync"
                                severity="warning"
                                @click="confirmUpdateAuthorisationCode"
                            />
                        </InputGroup>
                        <InlineMessage
                            v-if="errors.authenticationCode"
                            class="mt-3"
                            >{{ errors.authenticationCode }}
                        </InlineMessage>
                    </div>
                    <Divider />
                </div>
                <div
                    class="flex justify-end flex-column md:flex-row gap-2 md:gap-0"
                >
                    <router-link
                        :to="{
                            name: 'EventGallery',
                            params: {
                                eventId: props.eventId,
                            },
                        }"
                    >
                        <Button
                            label="Cancel Edit"
                            class="p-button-rounded w-52 p-button mr-3"
                        ></Button>
                    </router-link>
                    <Button
                        form="eventDetailsForm"
                        label="Save Changes"
                        type="submit"
                        class="p-button-rounded mr-3 w-52 p-button-success"
                    ></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped></style>
