import { ResourcesConfig } from '@aws-amplify/core'

const getAmplifyResourceConfig = (): ResourcesConfig => {
    return {
        API: {
            REST: {
                photoUpload: {
                    endpoint: import.meta.env.VITE_API_ENDPOINT,
                    region: 'eu-west-1',
                },
            },
            GraphQL: {
                endpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT,
                region: 'eu-west-1',
                defaultAuthMode: 'userPool',
            },
        },
        Auth: {
            Cognito: {
                identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
                userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
                userPoolClientId: import.meta.env
                    .VITE_COGNITO_USER_POOL_WEB_CLIENT_ID,
                signUpVerificationMethod: undefined,
                mfa: {
                    status: 'off',
                    smsEnabled: false,
                    totpEnabled: false,
                },
                loginWith: {
                    oauth: {
                        domain: 'snap-pix-app-wibberleyl.auth.eu-west-1.amazoncognito.com',
                        redirectSignIn: ['http://localhost:5173/'],
                        redirectSignOut: ['http://localhost:5173/'],
                        responseType: 'code',
                        scopes: [
                            'phone',
                            'email',
                            'profile',
                            'openid',
                            'aws.cognito.signin.user.admin',
                        ],
                        providers: ['Google', 'Facebook'],
                    },
                    email: true,
                    phone: false,
                    username: false,
                },
                userAttributes: {
                    email: { required: true },
                },
                passwordFormat: {
                    minLength: 8,
                    requireLowercase: true,
                    requireUppercase: true,
                    requireNumbers: true,
                    requireSpecialCharacters: true,
                },
                allowGuestAccess: true,
            },
        },
    }
}

export { getAmplifyResourceConfig }
