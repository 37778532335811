/**
 * Retries a function n number of times before giving up
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
export async function retry<T extends (...arg0: any[]) => any>(
    fn: T,
    args: Parameters<T>,
    maxTry: number,
    timebetweenRetries = 1000,
    retryCount = 1
): Promise<Awaited<ReturnType<T>>> {
    const currRetry = retryCount
    try {
        return await fn(...args)
    } catch (e) {
        await delay(timebetweenRetries)

        console.log(`Retry ${currRetry} failed.`)
        if (currRetry > maxTry) {
            console.log(`All ${maxTry} retry attempts exhausted`)
            throw e
        }

        return retry(fn, args, maxTry, timebetweenRetries, currRetry + 1)
    }
}

function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
