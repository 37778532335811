<script lang="ts" setup></script>

<template>
    <div class="surface-card p-5 shadow-2 border-round flex-auto xl:ml-5">
        <div class="text-900 font-semibold text-lg mt-3">Profile</div>
        <Divider></Divider>
        <div class="flex gap-5 flex-column-reverse md:flex-row">
            <div class="flex-auto p-fluid">
                <div class="mb-4">
                    <label for="email" class="block font-normal text-900 mb-2"
                        >Name</label
                    >
                    <InputText id="email" type="text" />
                </div>
                <div>
                    <Button label="Update Profile" class="w-auto mt-3"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
