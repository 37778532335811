<script lang="ts" setup>
import { computed, ref, onBeforeUnmount } from 'vue'

const props = defineProps({
    date: Date,
})

if (!props.date) {
    throw new Error('Date prop is required')
}

const date = props.date.getTime() / 1000
const now = ref(Math.trunc(new Date().getTime() / 1000))

const two_digits = (value: number) => {
    return value.toString().padStart(2, '0')
}

const seconds = computed(() => {
    return (date - now.value) % 60
})

const minutes = computed(() => {
    return Math.trunc((date - now.value) / 60) % 60
})

const hours = computed(() => {
    return Math.trunc((date - now.value) / 60 / 60) % 24
})

const days = computed(() => {
    return Math.trunc((date - now.value) / 60 / 60 / 24)
})

const interval = window.setInterval(() => {
    now.value = Math.trunc(new Date().getTime() / 1000)
}, 1000)

onBeforeUnmount(() => {
    window.clearInterval(interval)
})
</script>

<template>
    <div class="flex flex-column m-1 md:m-3" v-if="days > 0">
        <p class="text-white text-7xl md:text-9xl font-thin text-center m-1">
            {{ two_digits(days) }}
        </p>
        <p
            class="text-amber-300 text-2xl md:text-3xl font-normal m-1 text-center"
        >
            Days
        </p>
    </div>
    <div class="flex flex-column m-1 md:m-3" v-if="hours > 0">
        <p class="text-white text-7xl md:text-9xl font-thin text-center m-1">
            {{ two_digits(hours) }}
        </p>
        <p
            class="text-amber-300 text-2xl md:text-3xl font-normal m-1 text-center"
        >
            Hours
        </p>
    </div>
    <div class="flex flex-column m-1 md:m-3">
        <p class="text-white text-7xl md:text-9xl font-thin text-center m-1">
            {{ two_digits(minutes) }}
        </p>
        <p
            class="text-amber-300 text-2xl md:text-3xl font-normal m-1 text-center"
        >
            Minutes
        </p>
    </div>
    <div class="flex flex-column m-1 md:m-3">
        <p class="text-white text-7xl md:text-9xl font-thin text-center m-1">
            {{ two_digits(seconds) }}
        </p>
        <p
            class="text-amber-300 text-2xl md:text-3xl font-normal m-1 text-center"
        >
            Seconds
        </p>
    </div>
</template>

<style></style>
