<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { useField, useForm } from 'vee-validate'
import { useEventStore } from '@stores/EventStore'
import { getCurrentUser } from 'aws-amplify/auth'
import { EventModel } from '@models/EventModel'

const username = ref<string | undefined>('')

const eventStore = useEventStore()
const upcomingEvents = ref<EventModel[]>([])
const recentlyEndedEvents = ref<EventModel[]>([])
const toast = useToast()
const successToastVisible = ref(false)
const createEventDialogVisible = ref(false)

const validationSchema = toTypedSchema(
    zod.object({
        name: zod.string().trim().min(3).max(50),
        description: zod.string().trim().min(3).max(250),
    })
)

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema,
})

const { value: name } = useField<string>('name')
const { value: description } = useField<string>('description')

watch(createEventDialogVisible, () => {
    resetForm()
})

const onSubmit = handleSubmit(async ({ name, description }, { resetForm }) => {
    try {
        const newEvent = await eventStore.createEvent(name, description)

        toast.removeGroup('editSuccess')

        toast.add({
            severity: 'success',
            summary: 'Your event has been created!',
            detail: { data: newEvent },
            group: 'editSuccess',
        })

        createEventDialogVisible.value = false

        resetForm()
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Message failed to send!',
            detail: 'The message failed to send. Please try emailing us directly at hello@delmdigital.co.uk',
            life: 3000,
        })
    }
})

const onClose = () => {
    successToastVisible.value = false
}

onMounted(async () => {
    const currentUser = await getCurrentUser()
    username.value = currentUser.signInDetails?.loginId

    await eventStore.fetchUpcomingEvents()
    upcomingEvents.value = [...eventStore.events]

    await eventStore.fetchRecentlyEndedEvents()
    recentlyEndedEvents.value = [...eventStore.events]
})
</script>

<template>
    <header class="px-6 py-5">
        <div
            class="flex w-full justify-content-between align-items-center surface-border pb-2 flex-column md:flex-row"
        >
            <p class="mt-0 mb-0 font-bold text-2xl text-500">
                Welcome back {{ username }}
            </p>
        </div>
    </header>

    <Toast position="bottom-center" group="editSuccess" @close="onClose">
        <template #message="slotProps">
            <div class="flex flex-column align-items-start" style="flex: 1">
                <span class="p-toast-summary flex align-items-center">
                    <span class="pi pi-exclamation-circle pr-1"></span>

                    {{ slotProps.message.summary }}
                </span>

                <div class="flex justify-evenly w-full my-2">
                    <router-link
                        :to="{
                            name: 'EventDetails',

                            params: {
                                eventId: slotProps.message.detail.data.eventId,
                            },
                        }"
                    >
                        <Button
                            v-ripple
                            class="p-button p-button-sm mt-2 ml-2 w-32"
                            label="View"
                        ></Button>
                    </router-link>

                    <router-link
                        :to="{
                            name: 'EventEdit',

                            params: {
                                eventId: slotProps.message.detail.data.eventId,
                            },
                        }"
                    >
                        <Button
                            v-ripple
                            class="p-button p-button-sm mt-2 ml-2 w-32"
                            label="Edit"
                        ></Button>
                    </router-link>
                </div>
            </div>
        </template>
    </Toast>

    <Dialog
        v-model:visible="createEventDialogVisible"
        appendTo="body"
        modal
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '52vw' }"
        header="Create New Event"
        :draggable="false"
        :resizable="false"
    >
        <template #header>
            <div class="flex flex-column gap-2">
                <h2 class="m-0 text-900 font-semibold text-xl line-height-3">
                    Create New Event
                </h2>

                <span class="text-600 text-base"
                    >Please enter a name and description for your new
                    event</span
                >
            </div>
        </template>

        <form
            id="newEventForm"
            class="flex flex-column gap-3 mt-3"
            @submit.prevent="onSubmit"
        >
            <div>
                <label for="event-name" class="block mb-1 text-color text-base"
                    >Event Name</label
                >

                <span class="p-input-icon-left w-full">
                    <InputGroup>
                        <InputGroupAddon>
                            <i class="pi pi-user"></i>
                        </InputGroupAddon>

                        <InputText
                            name="event-name"
                            type="text"
                            class="w-full"
                            id="event-name"
                            v-model="name"
                        />
                    </InputGroup>

                    <InlineMessage v-if="errors.name" class="mt-3"
                        >{{ errors.name }}
                    </InlineMessage>
                </span>
            </div>

            <div>
                <label for="description" class="block mb-1 text-color text-base"
                    >Event Description</label
                >

                <span class="p-input-icon-left w-full">
                    <InputGroup>
                        <InputGroupAddon>
                            <i class="pi pi-credit-card"></i>
                        </InputGroupAddon>

                        <InputText
                            name="description"
                            type="text"
                            class="w-full"
                            id="description"
                            v-model="description"
                        />
                    </InputGroup>

                    <InlineMessage v-if="errors.description" class="mt-3"
                        >{{ errors.description }}
                    </InlineMessage>
                </span>
            </div>
        </form>

        <template #footer>
            <div class="flex gap-3 justify-content-end surface-border pt-5">
                <Button
                    label="Cancel"
                    @click="createEventDialogVisible = false"
                    class="p-button-text"
                ></Button>

                <Button
                    form="newEventForm"
                    label="Create"
                    type="submit"
                    class="p-button-rounded"
                ></Button>
            </div>
        </template>
    </Dialog>

    <div
        class="flex w-full justify-content-between align-items-center surface-border pb-2 flex-column md:flex-row"
    >
        <Button
            @click="createEventDialogVisible = true"
            v-ripple
            raised
            rounded
            class="ml-6"
            label="Create new event"
        ></Button>
    </div>
    <section class="px-6 py-5">
        <div
            class="flex w-full justify-content-between align-items-center surface-border pb-1 flex-column md:flex-row"
        >
            <h2 class="mt-0 mb-0 font-normal text-base font-bold text-500">
                Upcoming events
            </h2>
        </div>
        <div class="flex flex-wrap -mx-1">
            <article
                v-for="(item, index) in upcomingEvents"
                :key="index"
                class="flex flex-column md:flex-row md:w-1/3 xl:w-1/6 p-1 border-round surface-border"
            >
                <div class="relative">
                    <router-link
                        :to="{
                            name: 'EventDetails',
                            params: { eventId: item.eventId },
                        }"
                        class="no-underline text-current group"
                    >
                        <img
                            :src="item.eventImage"
                            alt="Image"
                            class="border-round w-full"
                        />
                        <p
                            class="absolute top-2 left-1/2 transform -translate-x-1/2 px-2 py-0 rounded-lg text-base font-normal bg-opacity-30 group-hover:text-black"
                        >
                            {{ item.name }}
                        </p>
                    </router-link>
                </div>
            </article>
        </div>
        <div
            class="flex w-full justify-content-between align-items-center surface-border pt-5 flex-column md:flex-row"
        >
            <h2 class="font-normal text-base font-bold text-500">
                Recently ended events
            </h2>
        </div>
        <div class="flex flex-wrap -mx-1">
            <article
                v-for="(item, index) in recentlyEndedEvents"
                :key="index"
                class="flex flex-column md:flex-row md:w-1/3 xl:w-1/6 p-1 border-round surface-border"
            >
                <div class="relative">
                    <router-link
                        :to="{
                            name: 'EventDetails',
                            params: { eventId: item.eventId },
                        }"
                        class="no-underline text-current group"
                    >
                        <img
                            :src="item.eventImage"
                            alt="Image"
                            class="border-round w-full"
                        />
                        <p
                            class="absolute top-2 left-1/2 transform -translate-x-1/2 px-2 py-0 rounded-lg text-base font-normal bg-opacity-30 group-hover:text-black"
                        >
                            {{ item.name }}
                        </p>
                    </router-link>
                </div>
            </article>
        </div>
    </section>
</template>

<style scoped></style>
