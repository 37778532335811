import { createRouter, createWebHistory } from 'vue-router'
import * as auth from 'aws-amplify/auth'
import Profile from '@pages/authenticated/settings/Profile.vue'
import Auth from '@pages/Auth.vue'
import Capture from '@pages/Capture.vue'
import EventList from '@pages/authenticated/events/EventList.vue'
import EventGallery from '@pages/authenticated/events/EventGallery.vue'
import EventEdit from '@pages/authenticated/events/EventEdit.vue'
import SettingsBase from '@layouts/authenticated/settings/SettingsBase.vue'
import SettingsHome from '@pages/authenticated/settings/Home.vue'
import Base from '@layouts/Base.vue'
import PageNotFound from '@pages/PageNotFound.vue'
import Home from '@pages/authenticated/Home.vue'
import EventAlbum from '@pages/authenticated/events/albums/EventAlbum.vue'

const routes = [
    {
        path: '/',
        name: 'Base',
        component: Base,
        meta: { requiresAuth: true },
        children: [
            { name: 'Home', path: '', component: Home },
            {
                path: '/settings/',
                name: 'SettingsBase',
                component: SettingsBase,
                children: [
                    {
                        path: '',
                        name: 'SettingsHome',
                        component: SettingsHome,
                        meta: { title: 'Settings' },
                    },
                    {
                        path: 'profile',
                        name: 'Profile',
                        component: Profile,
                        meta: { title: 'Profile' },
                    },
                ],
            },
            {
                path: '/events/',
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'EventList',
                        component: EventList,
                        meta: { title: 'Snap Pix Events' },
                    },
                    {
                        path: ':eventId',
                        name: 'EventGallery',
                        component: EventGallery,
                        props: true,
                        children: [
                            {
                                path: 'albums/',
                                name: 'EventAlbums',
                                component: EventAlbum,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: ':eventId/edit',
                        name: 'EventEdit',
                        component: EventEdit,
                        props: true,
                    },
                ],
            },
            { path: '/:pathMatch(.*)*', component: PageNotFound },
        ],
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
    },
    {
        path: '/capture/:eventId',
        name: 'Capture',
        component: Capture,
        props: true,
        meta: { title: 'Capture Photo' },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})

router.beforeResolve((to, _, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        auth.getCurrentUser()
            .then(() => {
                next()
            })
            .catch(() => {
                next({
                    path: '/auth',
                    query: { redirect: to.fullPath },
                })
            })
    } else {
        next()
    }
})

router.afterEach((to) => {
    const title = (to.meta.title as string) || 'Snap Pix'
    if (title) {
        document.title = title
    }
})

export default router
